import { message } from "antd";

const AlertMessage = (type, data) => {
    message.config({
        top: 100,
        duration: 3,
    });

    return type === "success"
        ? message.success(data)
        : type === "error"
        ? message.error(data)
        : type === "warning"
        ? message.warning(data)
        : message.info(data);
};

export default AlertMessage;
