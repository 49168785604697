import { fromJS } from "immutable";
import { VERIFY_PLATFORM_FAILED, VERIFY_TIKTOK_PLATFORM_FAILED } from "./constants";

export const initState = fromJS({
    err: null,
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

export default function platformReducer(state = initState, action) {
    switch (action.type) {
        case VERIFY_PLATFORM_FAILED:
            return setError(state, action.err);
        case VERIFY_TIKTOK_PLATFORM_FAILED:
            return setError(state, action.err);
        default:
            return state;
    }
}
