import { fromJS } from "immutable";
import {
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNT_FAILED,
    CLEAR_ACCOUNT,
    TOGGLE_MODAL_LOADING,
    TOGGLE_MODAL,
    GET_FILE_SUCCESS,
    GET_FILE_FAILED,
    TOGGLE_UPLOADING,
} from "./constants";

export const initState = fromJS({
    account: {
        name: "",
        readable_address: "",
        opening_hours: "",
        business_registration_number: "",
        tax_identification_number: "",
        // business_registration_photo: {},
        // logo: {},
    },
    pdfFile: "",
    err: null,
    isUploading: false,
    isModalLoading: false,
    isModalOpen: false,
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

// fetch ACCOUNT
export const setAccount = (state, action) => state.set("account", action.data[0]);

// clear DATAS
export const clearAccountState = (state) => state.update("account", () => initState.get("account"));

// MODAL
export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

//
export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

export const setPDFFile = (state, action) => state.set("pdfFile", action.data);

export const toggleUploading = (state) => state.update("isUploading", (loading) => !loading);

export default function accountReducer(state = initState, action) {
    switch (action.type) {
        case FETCH_ACCOUNT_SUCCESS:
            return setAccount(state, action);

        case FETCH_ACCOUNT_FAILED:
            return setError(state, action.err);

        case CLEAR_ACCOUNT:
            return clearAccountState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case TOGGLE_UPLOADING:
            return toggleUploading(state);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case GET_FILE_SUCCESS:
            return setPDFFile(state, action);

        case GET_FILE_FAILED:
            return setError(state, action.err);

        default:
            return state;
    }
}
