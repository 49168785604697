import { takeLatest, put, call, select, all } from "redux-saga/effects";
import { List, Map } from "immutable";
import _ from "lodash";
import qs from "query-string";
import moment from "moment";
import apiCall from "../../utils/ApiService";
import {
    fetchOrdersSuccess,
    fetchOrdersFailed,
    toggleIsTableLoading,
    toggleModalState,
    printSelectedSuccess,
    printOneRowSuccess,
    fetchSearchOptionsSuccess,
    fetchSearchOptionsFailed,
    toggleIsModalLoading,
    toggleIsFirstLoad,
    // fetchOrders,
    setZipResult,
    filenamePopup,
    clearAllOrders,
} from "./actions";
import {
    FETCH_ORDERS,
    PRINT_SELECTED_SALES_INVOICES,
    PRINT_ONE_ROW,
    FETCH_FILTER_OPTIONS,
    FETCH_FILTER_OPTIONS_FACTORY,
} from "./constants";
import { makeSelectTableControls, makeSelectSelectedOrders, makeSelectFirstLoad } from "./selectors";
import AlertMessage from "../../components/AlertMessage";

const ordersURL = "/sales-invoice";

export function* fetchOrdersWorker({ tableControls }) {
    let curTableControls = yield select(makeSelectTableControls);

    if (curTableControls.current_page === null) {
        yield put(clearAllOrders());
        curTableControls = yield select(makeSelectTableControls);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const i in tableControls) {
        if (tableControls.status === undefined) {
            // eslint-disable-next-line no-param-reassign
            tableControls.status = "All";
        } else if (tableControls[i] === "All") {
            // eslint-disable-next-line no-param-reassign
            tableControls[i] = undefined;
        }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const i in curTableControls) {
        if (curTableControls.status === undefined) {
            curTableControls.status = "All";
        } else if (curTableControls[i] === "All") {
            curTableControls[i] = undefined;
        }
    }

    const mergeTableControls = _.pickBy({
        ...curTableControls,
        ...tableControls,
    });
    const serializedTableControls = qs.stringify(mergeTableControls);
    const ordersURLwParams = `${ordersURL}?${serializedTableControls}`;
    try {
        const ordersTblCtrls = JSON.parse(localStorage.getItem("ordersTblCtrls"));
        const ordersData = JSON.parse(localStorage.getItem("ordersData"));
        const curPage = JSON.parse(localStorage.getItem("curPage"));
        const totalEntries = JSON.parse(localStorage.getItem("totalEntries"));

        mergeTableControls.start_date = moment(mergeTableControls.start_date).toISOString();
        mergeTableControls.end_date = moment(mergeTableControls.end_date).toISOString();

        yield put(
            fetchOrdersSuccess(List(ordersData), Map({ ...ordersTblCtrls, current_page: curPage }), totalEntries),
        );

        if (!_.isEqual(ordersTblCtrls, mergeTableControls)) {
            yield put(toggleIsTableLoading());
            const { data, current_page, total_entries } = yield call(apiCall, "GET", ordersURLwParams);

            yield put(fetchOrdersSuccess(List(data), Map({ ...mergeTableControls, current_page }), total_entries));
            yield all([
                localStorage.setItem("ordersTblCtrls", JSON.stringify(mergeTableControls)),
                localStorage.setItem("ordersData", JSON.stringify(data)),
                localStorage.setItem("curPage", JSON.stringify(current_page)),
                localStorage.setItem("totalEntries", JSON.stringify(total_entries)),
            ]);
            yield put(toggleIsTableLoading());
        }
    } catch ({ response }) {
        if (response) {
            const { data } = response;
            yield put(fetchOrdersFailed(data.error));

            AlertMessage("error", response.data.message);
        }
    }
}

export function* fetchSearchOptions() {
    try {
        const data = yield call(apiCall, "GET", `${ordersURL}/queryList`);
        yield put(fetchSearchOptionsSuccess(data));
    } catch ({ response }) {
        const { data } = response;
        yield put(fetchSearchOptionsFailed(data.error));
        AlertMessage("error", response.data.message);
    }
}

export function* fetchSearchOptionsFactory() {
    try {
        const data = yield call(apiCall, "GET", `${ordersURL}/queryList`);
        if (yield select(makeSelectFirstLoad)) {
            yield put(fetchSearchOptionsSuccess(data));
            // Code reserved for old initial loading
            /* let initDefaultFilter = {
                unique_platform_id: "PH7S4289KG",
                status: "delivered",
                print_status: "never_been_printed",
            };

            if (!_.isNil(localStorage.getItem("ordersTblCtrls"))) {
                const objStartDate = JSON.parse(localStorage.getItem("ordersTblCtrls")).start_date;
                const objEndDate = JSON.parse(localStorage.getItem("ordersTblCtrls")).end_date;
                const objOrdersFilter = JSON.parse(localStorage.getItem("ordersTblCtrls"));
                initDefaultFilter = objOrdersFilter;

                if (!_.isNil(objStartDate) && !_.isNil(objEndDate)) {
                    initDefaultFilter = _.defaults(
                        {
                            start_date: moment(objStartDate),
                            end_date: moment(objEndDate),
                        },
                        objOrdersFilter,
                    );
                }
            }
            yield put(fetchOrders({ ...initDefaultFilter })); */
            yield put(toggleIsFirstLoad());
        }
    } catch ({ response }) {
        const { data } = response;
        yield put(fetchSearchOptionsFailed(data.error));
        AlertMessage("error", response.data.message);
    }
}

export function* printSelectedSalesInvoices() {
    yield put(toggleModalState());
    yield put(toggleIsModalLoading());
    const ordersTblCtrls = JSON.parse(localStorage.getItem("ordersTblCtrls"));
    let response;
    try {
        const selectedItems = yield select(makeSelectSelectedOrders);
        if (selectedItems.length === 0) {
            response = yield call(apiCall, "POST", `/printer/download-all-invoices`, {
                data: ordersTblCtrls,
                responseType: "blob",
            });
        } else {
            const package_ids = {
                package_ids: selectedItems.map((item) => item.id),
            };
            response = yield call(apiCall, "POST", `/printer/invoice-retrieval`, {
                data: package_ids,
                responseType: "blob",
            });
        }

        const extType = response.type;

        const newBlob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);

        if (extType && extType === "application/pdf") {
            return yield all([put(printSelectedSuccess(url)), put(toggleIsModalLoading())]);
        }

        return yield all([
            put(toggleModalState()),
            put(toggleIsModalLoading()),
            put(setZipResult(response)),
            put(filenamePopup()),
        ]);
    } catch (err) {
        // yield put(printSelectedFailed(err));
        yield put(toggleModalState());
        yield put(toggleIsModalLoading());
        AlertMessage("error", err.message);
    }
    return yield put(toggleIsModalLoading());
}

export function* printOneRow({ selected }) {
    yield put(toggleModalState());
    yield put(toggleIsModalLoading());
    const package_ids = {
        package_ids: selected,
    };
    try {
        const response = yield call(apiCall, "POST", `/printer/invoice-retrieval`, {
            data: package_ids,
            responseType: "blob",
        });

        const extType = response.type;

        const newBlob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);

        if (extType && extType === "application/pdf") {
            return yield all([put(printOneRowSuccess(url)), put(toggleIsModalLoading())]);
        }

        return yield all([
            put(toggleModalState()),
            put(toggleIsModalLoading()),
            put(setZipResult(response)),
            put(filenamePopup()),
            put(printOneRowSuccess(url)),
        ]);
    } catch ({ response }) {
        if (response.status !== 200) {
            response.data.text().then((error) => AlertMessage("error", error));
        } else {
            AlertMessage("error", response.data.message);
            // yield put(toggleModalState());
        }
    }
    return yield put(toggleIsModalLoading());
}

export default function* ordersWatcher() {
    yield takeLatest(FETCH_ORDERS, fetchOrdersWorker);
    yield takeLatest(PRINT_SELECTED_SALES_INVOICES, printSelectedSalesInvoices);
    yield takeLatest(PRINT_ONE_ROW, printOneRow);
    yield takeLatest(FETCH_FILTER_OPTIONS, fetchSearchOptions);
    yield takeLatest(FETCH_FILTER_OPTIONS_FACTORY, fetchSearchOptionsFactory);
}
