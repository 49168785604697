import {
    FETCH_ORDERS,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_FAILED,
    CLEAR_ORDERS,
    TOGGLE_TABLE_LOADING,
    PRINT_SELECTED_SALES_INVOICES,
    PRINT_SELECTED_SUCCESS,
    PRINT_SELECTED_FAILED,
    TOGGLE_MODAL,
    PRINT_ONE_ROW,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    FETCH_FILTER_OPTIONS,
    FETCH_FILTER_OPTIONS_FACTORY,
    FETCH_FILTER_OPTIONS_FAILED,
    FETCH_FILTER_OPTIONS_SUCCESS,
    HANDLE_SELECT_ONE,
    HANDLE_SELECT_PARENT,
    HANDLE_SELECT_MASTER,
    UPDATE_SELECT_MASTER,
    HANDLE_DESELECT_ALL,
    TOGGLE_MODAL_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    UPDATE_DATE_RANGE,
    CLEAR_ALL_ORDERS,
    TOGGLE_FIRST_LOAD,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
    CLEAR_AFTER_PRINT,
    SORT_CHANGE,
    UPDATE_SELECTED_ORDER,
    UPDATE_MASTER_SELECTED_ORDER,
    VALUED_TABLE_LOADING,
} from "./constants";

export const fetchOrders = (tableControls = {}) => ({
    type: FETCH_ORDERS,
    tableControls,
});

export const fetchOrdersSuccess = (data, tableControls, totalEntries) => ({
    type: FETCH_ORDERS_SUCCESS,
    data,
    tableControls,
    totalEntries,
});

export const fetchOrdersFailed = (err) => ({
    type: FETCH_ORDERS_FAILED,
    err,
});

export const fetchSearchOptions = () => ({
    type: FETCH_FILTER_OPTIONS,
});

export const fetchSearchOptionsFactory = () => ({
    type: FETCH_FILTER_OPTIONS_FACTORY,
});

export const fetchSearchOptionsSuccess = (data) => ({
    type: FETCH_FILTER_OPTIONS_SUCCESS,
    data,
});

export const fetchSearchOptionsFailed = (err) => ({
    type: FETCH_FILTER_OPTIONS_FAILED,
    err,
});

export const clearOrders = () => ({
    type: CLEAR_ORDERS,
});

export const clearAllOrders = () => ({
    type: CLEAR_ALL_ORDERS,
});

export const toggleIsTableLoading = () => ({
    type: TOGGLE_TABLE_LOADING,
});

export const valuedIsTableLoading = (value) => ({
    type: VALUED_TABLE_LOADING,
    value,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const toggleIsFirstLoad = () => ({
    type: TOGGLE_FIRST_LOAD,
});

export const printSelectedSalesInvoices = () => ({
    type: PRINT_SELECTED_SALES_INVOICES,
});

export const printSelectedSuccess = (data) => ({
    type: PRINT_SELECTED_SUCCESS,
    data,
});

export const printSelectedFailed = (err) => ({
    type: PRINT_SELECTED_FAILED,
    err,
});

export const printOneRow = (selected) => ({
    type: PRINT_ONE_ROW,
    selected,
});

export const printOneRowSuccess = (data) => ({
    type: PRINT_ONE_ROW_SUCCESS,
    data,
});

export const printOneRowFailed = (err) => ({
    type: PRINT_ONE_ROW_FAILED,
    err,
});

export const handleSelectOne = (data = {}, parentIndex, index) => ({
    type: HANDLE_SELECT_ONE,
    data,
    parentIndex,
    index,
});

export const handleSelectParent = (data = [], index) => ({
    type: HANDLE_SELECT_PARENT,
    data,
    index,
});

export const handleSelectMaster = () => ({
    type: HANDLE_SELECT_MASTER,
});

export const updateSelectMaster = () => ({
    type: UPDATE_SELECT_MASTER,
});

export const handleDeselectAll = () => ({
    type: HANDLE_DESELECT_ALL,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const updateDateRange = (names, values) => ({
    type: UPDATE_DATE_RANGE,
    data: {
        [`${names[0]}`]: values[0],
        [`${names[1]}`]: values[1],
    },
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const setZipResult = (data) => ({
    type: SET_ZIP_RESULT,
    data,
});

export const clearAfterPrint = () => ({
    type: CLEAR_AFTER_PRINT,
});

export const handleSortChange = (sortBy, sortType) => ({
    type: SORT_CHANGE,
    sortBy,
    sortType,
});

export const updateSelectedOrder = (data, checked) => ({
    type: UPDATE_SELECTED_ORDER,
    data,
    checked,
});

export const updateMasterSelected = (checked) => ({
    type: UPDATE_MASTER_SELECTED_ORDER,
    checked,
});
