const navList = [
    // {
    //     name: "Landing Page",
    //     path: "/",
    //     auth: "public",
    //     exact: true
    // },
    // {
    //     name: "Login",
    //     path: "/login",
    //     auth: "public",
    //     exact: true
    // },
    // {
    //     name: "Register",
    //     path: "/register",
    //     auth: "public",
    //     exact: true
    // },
    {
        name: "Home",
        path: "/home",
        auth: "private",
        exact: true,
    },
    /* {
        name: "Account",
        path: "/accounts",
        auth: "private",
        exact: true,
    }, */
    {
        name: "Account",
        auth: "private",
        // exact: true,
        children: [
            {
                name: "Profile",
                path: "/accounts",
                auth: "private",
                exact: true,
            },
            {
                name: "Shops",
                path: "/shops",
                auth: "private",
                exact: true,
            },
        ],
        exact: true,
    },
    {
        name: "Purchase Orders",
        path: "/purchase-orders",
        auth: "private",
        exact: true,
    },
    {
        name: "Orders",
        path: "/orders",
        auth: "private",
        exact: true,
    },
    {
        name: "Collection Receipt",
        path: "/collection-receipt",
        auth: "private",
        exact: true,
    },
    {
        name: "Campaign Tracker",
        path: "/campaign-tracker-report",
        auth: "private",
        exact: true,
    },
    {
        name: "Reports",
        path: "/reports",
        auth: "private",
        exact: true,
    },
    {
        name: "Files",
        path: "/files",
        auth: "private",
        exact: true,
    },
];

export default navList;
