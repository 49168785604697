import { takeLatest, call } from "redux-saga/effects";
import apiCall from "../../utils/ApiService";
import { GET_SHOPEEV2 } from "./constants";
import AlertMessage from "../../components/AlertMessage";

export function* getShopeeV2URLWorker() {
    try {
        const { url } = yield call(apiCall, "GET", "/shopee-v2/generate-url");
        window.open(url);
    } catch ({ response }) {
        AlertMessage("error", response.data.message);
    }
}

export default function* platformWatcher() {
    yield takeLatest(GET_SHOPEEV2, getShopeeV2URLWorker);
}
