/* eslint-disable indent */
import { message } from "antd";

const MessageDownloading = (key, res, type) =>
    message
        .loading({
            content: `Download in progress...`,
            duration: res ? 0.5 : 0,
            key,
        })
        .then(() =>
            type === "success"
                ? message.success("Download finished.", 10)
                : message.info("Report is still processing. Please check files tab for newly generated file.", 10),
        );

export default MessageDownloading;
