export const FETCH_ORDERS = "Orders/FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "Orders/FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILED = "Orders/FETCH_ORDERS_FAILED";

export const FETCH_FILTER_OPTIONS = "Orders/FETCH_FILTER_OPTIONS";
export const FETCH_FILTER_OPTIONS_FACTORY = "Orders/FETCH_FILTER_OPTIONS_FACTORY";
export const FETCH_FILTER_OPTIONS_SUCCESS = "Orders/FETCH_FILTER_OPTIONS_SUCCESS";
export const FETCH_FILTER_OPTIONS_FAILED = "Orders/FETCH_FILTER_OPTIONS_FAILED";

export const CLEAR_ORDERS = "Orders/CLEAR_ORDERS";
export const CLEAR_ALL_ORDERS = "Orders/CLEAR_ALL_ORDERS";

export const TOGGLE_TABLE_LOADING = "Orders/TOGGLE_TABLE_LOADING";
export const VALUED_TABLE_LOADING = "Orders/VALUED_TABLE_LOADING";
export const TOGGLE_MODAL = "Orders/TOGGLE_MODAL";
export const TOGGLE_MODAL_LOADING = "Orders/TOGGLE_MODAL_LOADING";

export const PRINT_SELECTED_SALES_INVOICES = "Orders/PRINT_SELECTED_SALES_INVOICES";
export const PRINT_SELECTED_SUCCESS = "Orders/PRINT_SELECTED_SUCCESS";
export const PRINT_SELECTED_FAILED = "Orders/PRINT_SELECTED_FAILED";

export const PRINT_ONE_ROW = "Orders/PRINT_ONE_ROW";
export const PRINT_ONE_ROW_SUCCESS = "Orders/PRINT_ONE_ROW_SUCCESS";
export const PRINT_ONE_ROW_FAILED = "Orders/PRINT_ONE_ROW_FAILED";

export const HANDLE_SELECT_ONE = "Orders/HANDLE_SELECT_ONE";
export const HANDLE_SELECT_PARENT = "Orders/HANDLE_SELECT_PARENT";
export const HANDLE_SELECT_MASTER = "Orders/HANDLE_SELECT_MASTER";
export const HANDLE_DESELECT_ALL = "Orders/HANDLE_DESELECT_ALL";

export const UPDATE_SELECT_MASTER = "Orders/UPDATE_SELECT_MASTER";
export const UPDATE_SELECTED_ORDER = "Orders/UPDATE_SELECTED_ORDER";
export const UPDATE_MASTER_SELECTED_ORDER = "Orders/UPDATE_MASTER_SELECTED_ORDER";

export const UPDATE_DROPDOWNS = "Orders/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "Orders/UPDATE_INPUTS";
export const UPDATE_DATE_RANGE = "Orders/UPDATE_DATE_RANGE";

export const TOGGLE_FIRST_LOAD = "Orders/TOGGLE_FIRST_LOAD";

export const FILENAME_POPUP = "Orders/FILENAME_POPUP";
export const SET_ZIP_RESULT = "Orders/SET_ZIP_RESULT";
export const CLEAR_AFTER_PRINT = "Orders/CLEAR_AFTER_PRINT";

export const SORT_CHANGE = "Orders/SORT_CHANGE";
