import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout, Button } from "antd";
import { NavLink } from "react-router-dom";
import TopNavs from "../../components/TopNavs";
import navList from "../../navList";
import Routes from "../../components/Routes";
import routes from "../../routes";
import "./defaultLayout.css";
import TempLogo from "../../assets/temp-logo";
import { logout } from "../Login/actions";

const DefaultLayout = (props) => {
    const { Content, Footer } = Layout;
    const { isLogin, logout } = props;
    return (
        <Layout>
            <header>
                <div className="logo">
                    <NavLink to="/">
                        <TempLogo />
                    </NavLink>
                </div>
                <TopNavs navList={navList} isLogin={isLogin} />
                {isLogin && (
                    <Button
                        className="auth-btn"
                        onClick={() => {
                            logout();
                        }}
                        ghost
                    >
                        Logout
                    </Button>
                )}
            </header>
            <Content className="site-layout">
                <div className="site-layout-background">
                    <Routes routeList={routes} isLogin={isLogin} />
                </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>Rezipt ©2020 Developed by Great Deals E-Commerce Corp.</Footer>
        </Layout>
    );
};

DefaultLayout.propTypes = {
    isLogin: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    logout,
};

export default connect(null, mapDispatchToProps)(DefaultLayout);
