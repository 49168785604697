import { createSelector } from "reselect";

export const authSelector = (state) => state.get("auth");

export const makeSelectIsLogin = createSelector(authSelector, (authState) => authState.get("isLogin"));

export const makeSelectCurrentUser = createSelector(authSelector, (authState) =>
    // eslint-disable-next-line no-prototype-builtins
    authState.get("currentUser").hasOwnProperty("_root") ? authState.get("currentUser").toJS() : {},
);
