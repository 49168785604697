export const GET_PLATFORMS = "KAMReport/GET_PLATFORMS";
export const GET_PLATFORMS_SUCCESS = "KAMReport/GET_PLATFORMS_SUCCESS";
export const GET_PLATFORMS_FAILED = "KAMReport/GET_PLATFORMS_FAILED";

export const HANDLE_PRINT = "KAMReport/HANDLE_PRINT";
export const HANDLE_PRINT_SUCCESS = "KAMReport/HANDLE_PRINT_SUCCESS";
export const HANDLE_PRINT_FAILED = "KAMReport/HANDLE_PRINT_FAILED";

export const TOGGLE_TABLE_LOADING = "KAMReport/TOGGLE_TABLE_LOADING";
export const TOGGLE_MODAL = "KAMReport/TOGGLE_MODAL";
export const TOGGLE_MODAL_LOADING = "KAMReport/TOGGLE_MODAL_LOADING";

export const UPDATE_DROPDOWNS = "KAMReport/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "KAMReport/UPDATE_INPUTS";

export const CLEAR_REPORTS = "KAMReport/CLEAR_REPORTS";
export const REPORT_CHANGE = "KAMReport/REPORT_CHANGE";

export const TOGGLE_FORM_OPEN = "KAMReport/TOGGLE_FORM_OPEN";
export const UPDATE_DATE_RANGE = "KAMReport/UPDATE_DATE_RANGE";

export const HANDLE_SELECT_ONE = "KAMReport/HANDLE_SELECT_ONE";
export const HANDLE_SELECT_MASTER = "KAMReport/HANDLE_SELECT_MASTER";
export const UPDATE_SELECT_MASTER = "KAMReport/UPDATE_SELECT_MASTER";
export const CLEAR_SELECTED_REPORTS = "KAMReport/CLEAR_SELECTED_REPORTS";

export const FILENAME_POPUP = "KAMReport/FILENAME_POPUP";
export const HANDLE_DL_FILES = "KAMReport/HANDLE_DL_FILES";
export const DL_WITH_DATE = "KAMReport/DL_WITH_DATE";

export const UPDATE_SELECTED_SHOPS = "KAMReport/UPDATE_SELECTED_SHOPS";

export const CLEAR_SELECTED_SHOPS = "KAMReport/CLEAR_SELECTED_SHOPS";
export const GET_FILTERED_PLATFORMS = "KAMReport/GET_FILTERED_PLATFORMS";

export const GET_LOADED = "KAMReport/GET_LOADED";
export const SET_DOWNLOAD_RES = "KAMReport/SET_DOWNLOAD_RES";
export const CLEAR_DOWNLOAD_RES = "KAMReport/CLEAR_DOWNLOAD_RES";

export const HANDLE_SELECT_FETCH = "KAMReport/HANDLE_SELECT_FETCH";

export const DOWNLOAD_AS_ONE_FILE = "KAMReport/DOWNLOAD_AS_ONE_FILE";
