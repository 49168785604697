import { createSelector } from "reselect";

export const filesSelector = (state) => state.get("filesReducer");

export const makeSelectFiles = createSelector(filesSelector, (filesState) =>
    filesState.get("files") ? filesState.get("files") : [],
);

export const makeSelectTotalItems = createSelector(filesSelector, (filesState) => filesState.get("total_entries"));

export const makeSelectTableControls = createSelector(filesSelector, (filesState) =>
    filesState.get("tableControls").toJS(),
);

export const makeSelectModalState = createSelector(filesSelector, (filesState) => filesState.get("isModalOpen"));

export const makeSelectIsTableLoading = createSelector(filesSelector, (filesState) => filesState.get("isTableLoading"));

export const makeSelectIsModalLoading = createSelector(filesSelector, (filesState) => filesState.get("isModalLoading"));

export const makeSelectPDFResults = createSelector(filesSelector, (filesState) => filesState.get("pdfResults"));

// FOR CHECKBOXES
export const makeSelectSelectedFiles = createSelector(filesSelector, (filesState) =>
    filesState.get("selectedFiles").length > 0 ? filesState.get("selectedFiles") : [],
);

export const makeSelectAllMaster = createSelector(filesSelector, (filesState) => filesState.get("selectAllMaster"));

export const makeSelectFileIDs = createSelector(filesSelector, (filesState) =>
    filesState.get("fileIDs") ? filesState.get("fileIDs") : [],
);

export const makeSelectFNModalState = createSelector(filesSelector, (filesState) => filesState.get("isFNModalOpen"));

export const makeSelectIsAllFiles = createSelector(filesSelector, (filesState) => filesState.get("isAllFiles"));
