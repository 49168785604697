// LOGIN
export const LOGIN = "Login/LOGIN";
export const LOGIN_SUCCESS = "Login/LOGIN_SUCCESS";
export const LOGIN_FAILED = "Login/LOGIN_FAILED";

// LOGOUT
export const LOGOUT = "Login/LOGOUT";
export const LOGOUT_SUCCESS = "Login/LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "Login/LOGOUT_FAILED";
export const CLEAR_LOCALSTORAGE = "Login/CLEAR_LOCALSTORAGE";
