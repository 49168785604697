import { createSelector } from "reselect";

export const accountSelector = (state) => state.get("accountReducer");

export const makeSelectAccount = createSelector(accountSelector, (accountState) =>
    accountState.get("account").size > 0 ? accountState.get("account").toJS() : accountState.get("account"),
);

export const makeSelectModalState = createSelector(accountSelector, (accountState) => accountState.get("isModalOpen"));

// export const makeSelectPDFFile = createSelector(
//   accountSelector,
//   (accountState) =>
//     accountState.get("pdfFile").size > 0
//       ? accountState.get("pdfFile").toJS()
//       : accountState.get("pdfFile")
// );

export const makeSelectPDFFile = createSelector(accountSelector, (accountState) => accountState.get("pdfFile"));

export const makeSelectUploading = createSelector(accountSelector, (accountState) => accountState.get("isUploading"));
