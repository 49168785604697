import { fromJS } from "immutable";

import {
    IMPORT_CR,
    SAVE_FILE,
    CLEAR_COLLECTION_RECEIPT,
    TOGGLE_FORM_OPEN,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    UPDATE_INPUTS,
    CLEAR_AFTER_PRINT,
} from "./constants";

export const initState = fromJS({
    collectionReceipt: [],
    businessDetails: {
        customer_name: "Scommerce Philippines, Inc Lazada E-Services Philippines, Inc.",
        registered_address:
            "23f Net Park Bldg. 5th Ave. E-Square Crescent Park West Bonifacio Global City, Taguig City",
        business_style_or_name: "Scommerce Philippines, Inc",
        tax_identification_number: "001-484-595-00000",
        paper_type: "collection_receipt",
    },
    err: null,
    isTableLoading: false,
    isModalLoading: false,
    isModalOpen: false,
    isFormOpen: false,
    pdfResults: "",
    file: "",
    isFNModalOpen: false,
});

export const setError = (state, err) => state.set("err", err);

export const setCollectionReceipt = (state, action) => state.set("collectionReceipt", action.data);

export const setFile = (state, action) => state.set("file", action.file);

export const clearCollectionReceiptState = (state) =>
    state
        .update("collectionReceipt", () => initState.get("collectionReceipt"))
        .update("pdfResults", () => initState.get("pdfResults"))
        .update("businessDetails", () => initState.get("businessDetails"))
        .update("file", () => initState.get("file"));

export const clearAfterPrintState = (state) => state.update("pdfResults", () => initState.get("pdfResults"));

export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

export const setPDFResults = (state, action) => state.set("pdfResults", action.data);

export const toggleFormOpenState = (state) => state.update("isFormOpen", (isOpen) => !isOpen);

export const updateInputs = (state, action) => state.updateIn(["businessDetails", action.name], () => action.value);

export default function collectionReceiptReducer(state = initState, action) {
    switch (action.type) {
        case IMPORT_CR:
            return setCollectionReceipt(state, action);

        case SAVE_FILE:
            return setFile(state, action);

        case CLEAR_COLLECTION_RECEIPT:
            return clearCollectionReceiptState(state);

        case TOGGLE_FORM_OPEN:
            return toggleFormOpenState(state);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case PRINT_ONE_ROW_SUCCESS:
            return setPDFResults(state, action);

        case PRINT_ONE_ROW_FAILED:
            return setError(state, action.err);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case CLEAR_AFTER_PRINT:
            return clearAfterPrintState(state);

        default:
            return state;
    }
}
