import {
    VERIFY_PLATFORM,
    VERIFY_PLATFORM_FAILED,
    VERIFY_PLATFORM_SUCCESS,
    VERIFY_TIKTOK_PLATFORM,
    VERIFY_TIKTOK_PLATFORM_FAILED,
    CLEAR_PLATFORM_FORM,
} from "./constants";

export const verifyPlatform = (params) => ({
    type: VERIFY_PLATFORM,
    params,
});

export const verifyTiktokPlatform = (params) => ({
    type: VERIFY_TIKTOK_PLATFORM,
    params,
});

export const verifyPlatformSuccess = (data) => ({
    type: VERIFY_PLATFORM_SUCCESS,
    data,
});

export const verifyPlatformFailed = (err) => ({
    type: VERIFY_PLATFORM_FAILED,
    err,
});

export const verifyTiktokPlatformFailed = (err) => ({
    type: VERIFY_TIKTOK_PLATFORM_FAILED,
    err,
});

export const clearPlatformForm = () => ({
    type: CLEAR_PLATFORM_FORM,
});
