import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGOUT_FAILED,
    LOGOUT_SUCCESS,
    CLEAR_LOCALSTORAGE,
} from "./constants";

// LOGIN
export const login = (username, password) => ({
    type: LOGIN,
    username,
    password,
});

export const loginSuccess = (currentUser) => ({
    type: LOGIN_SUCCESS,
    currentUser,
});

export const loginFailed = (err) => ({
    type: LOGIN_FAILED,
    err,
});

// LOGOUT
export const logout = () => ({
    type: LOGOUT,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutFailed = (err) => ({
    type: LOGOUT_FAILED,
    err,
});

export const clearLocalStorage = () => ({
    type: CLEAR_LOCALSTORAGE,
});
