import {
    GET_PLATFORMS,
    GET_PLATFORMS_FAILED,
    GET_PLATFORMS_SUCCESS,
    HANDLE_PRINT,
    HANDLE_PRINT_SUCCESS,
    HANDLE_PRINT_FAILED,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    CLEAR_REPORTS,
    REPORT_CHANGE,
    TOGGLE_FORM_OPEN,
    UPDATE_DATE_RANGE,
    HANDLE_SELECT_ONE,
    HANDLE_SELECT_MASTER,
    UPDATE_SELECT_MASTER,
    FILENAME_POPUP,
    HANDLE_DL_FILES,
    DL_WITH_DATE,
    UPDATE_SELECTED_SHOPS,
    CLEAR_SELECTED_SHOPS,
    GET_FILTERED_PLATFORMS,
    GET_LOADED,
    SET_DOWNLOAD_RES,
    CLEAR_DOWNLOAD_RES,
    CLEAR_SELECTED_REPORTS,
    HANDLE_SELECT_FETCH,
    DOWNLOAD_AS_ONE_FILE,
} from "./constants";

export const getPlatforms = (tableControls = {}) => ({
    type: GET_PLATFORMS,
    tableControls,
});

export const getPlatformsSuccess = (data, tableControls, totalEntries) => ({
    type: GET_PLATFORMS_SUCCESS,
    data,
    tableControls,
    totalEntries,
});

export const getPlatformsFailed = (err) => ({
    type: GET_PLATFORMS_FAILED,
    err,
});

export const clearReports = () => ({
    type: CLEAR_REPORTS,
});

export const handlePrint = (platformId, docType) => ({
    type: HANDLE_PRINT,
    platformId,
    docType,
});

export const handlePrintSuccess = (data) => ({
    type: HANDLE_PRINT_SUCCESS,
    data,
});

export const handlePrintFailed = (err) => ({
    type: HANDLE_PRINT_FAILED,
    err,
});

export const toggleIsTableLoading = () => ({
    type: TOGGLE_TABLE_LOADING,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const reportChange = (_id, value) => ({
    type: REPORT_CHANGE,
    _id,
    value,
});

export const toggleFormOpen = () => ({
    type: TOGGLE_FORM_OPEN,
});

export const updateDateRange = (names, values) => ({
    type: UPDATE_DATE_RANGE,
    data: {
        [`${names[0]}`]: values[0],
        [`${names[1]}`]: values[1],
    },
});

// checkbox selection
export const handleSelectOne = (data = {}, parentIndex) => ({
    type: HANDLE_SELECT_ONE,
    data,
    parentIndex,
});

export const handleSelectMaster = () => ({
    type: HANDLE_SELECT_MASTER,
});

export const updateSelectMaster = () => ({
    type: UPDATE_SELECT_MASTER,
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const handleDLFiles = (ids, filename) => ({
    type: HANDLE_DL_FILES,
    ids,
    filename,
});

export const dlWithDate = (value) => ({
    type: DL_WITH_DATE,
    value,
});

export const updateSelectedShops = (value) => ({
    type: UPDATE_SELECTED_SHOPS,
    value,
});

export const clearSelectedShops = () => ({
    type: CLEAR_SELECTED_SHOPS,
});

export const getFilteredPlatforms = (data) => ({
    type: GET_FILTERED_PLATFORMS,
    data,
});

export const getLoaded = (loaded) => ({
    type: GET_LOADED,
    loaded,
});

export const setDownloadRes = (res) => ({
    type: SET_DOWNLOAD_RES,
    res,
});

export const clearDownloadRes = () => ({
    type: CLEAR_DOWNLOAD_RES,
});

export const clearSelectedReports = () => ({
    type: CLEAR_SELECTED_REPORTS,
});

export const handleSelectFetch = () => ({
    type: HANDLE_SELECT_FETCH,
});

export const downloadAs1File = (value) => ({
    type: DOWNLOAD_AS_ONE_FILE,
    value,
});
