import { takeLatest, put, call, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { Map } from "immutable";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { verifySuccess, editShopSuccess } from "./actions";
import { VERIFY, EDITSHOP } from "./constants";

let shopifyToken;
export function* verifyWorker({ ...creds }) {
    try {
        const data = yield call(apiCall, "POST", "/shopify/shop", {
            data: {
                credentials: { ...creds.shopifyData },
            },
        });

        shopifyToken = {
            ...data.shop,
            shopify_url: data.shop.myshopify_domain,
            unique_platform_id: data.shop.id,
            api_key: creds.shopifyData.apiKey,
            api_secret: creds.shopifyData.password,
        };
        yield all([put(verifySuccess(Map(data.shop))), put(replace("/shopify"))]);
    } catch ({ response }) {
        AlertMessage("Error", response.data.message);
    }
}

export function* editShopWorker({ ...creds }) {
    try {
        yield call(apiCall, "POST", "/platforms", {
            data: {
                platform: {
                    name: "Shopify",
                    ...creds.editedShop,
                },
                token: { ...shopifyToken },
            },
        });

        yield all([put(editShopSuccess()), put(replace("/home"))]);
    } catch ({ response }) {
        AlertMessage("Error", response.data.message);
    }
}

export default function* shopifyWatcher() {
    yield takeLatest(VERIFY, verifyWorker);
    yield takeLatest(EDITSHOP, editShopWorker);
}
