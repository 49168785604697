// FETCH ACCOUNT
export const FETCH_ACCOUNT = "Account/FETCH_ACCOUNT";
export const FETCH_ACCOUNT_SUCCESS = "Account/FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNT_FAILED = "Account/FETCH_ACCOUNT_FAILED";

// EDIT
export const EDIT_ACCOUNT = "Account/EDIT_ACCOUNT";
export const EDIT_ACCOUNT_SUCCESS = "Account/EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAILED = "Account/EDIT_ACCOUNT_FAILED";

// CLEAR
export const CLEAR_ACCOUNT = "Account/CLEAR_ACCOUNT";

// MODAL
export const TOGGLE_MODAL = "Account/TOGGLE_MODAL";
export const TOGGLE_MODAL_LOADING = "Account/TOGGLE_MODAL_LOADING";
export const TOGGLE_UPLOADING = "Account/TOGGLE_UPLOADING";

// get file
export const GET_FILE = "Account/GET_FILE";
export const GET_FILE_SUCCESS = "Account/GET_FILE_SUCCESS";
export const GET_FILE_FAILED = "Account/GET_FILE_FAILED";
