import { all } from "redux-saga/effects";
import authWatcher from "./containers/Login/saga";
import registerWatcher from "./containers/Register/saga";
import accountWatcher from "./containers/Account/saga";
import shopifyWatcher from "./containers/Shopify/saga";
import homeWatcher from "./containers/Home/saga";
import ordersWatcher from "./containers/Orders/saga";
import accountFormWatcher from "./containers/AccountForm/saga";
import platformWatcher from "./containers/Platforms/saga";
import filesWatcher from "./containers/Files/saga";
import reportsWatcher from "./containers/Reports/saga";
import effieWatcher from "./containers/Effie/saga";
import remittanceWatcher from "./containers/Remittance/saga";
import camsReportsWatcher from "./containers/ReportCampaignTracker/saga";
import collectionReceiptWatcher from "./containers/CollectionReceipt/saga";
import shopsWatcher from "./containers/ShopManager/saga";
export default function* rootSaga() {
    yield all([
        authWatcher(),
        registerWatcher(),
        accountWatcher(),
        shopifyWatcher(),
        homeWatcher(),
        ordersWatcher(),
        accountFormWatcher(),
        platformWatcher(),
        filesWatcher(),
        reportsWatcher(),
        effieWatcher(),
        remittanceWatcher(),
        camsReportsWatcher(),
        collectionReceiptWatcher(),
        shopsWatcher(),
    ]);
}
