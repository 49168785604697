import { takeLatest, call, put, all } from "redux-saga/effects";
import moment from "moment";
import FileDownload from "js-file-download";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { HANDLE_PRINT } from "./constants";
import { toggleModalState, toggleIsModalLoading } from "./actions";

const printerUrl = "/reports/invoices-tracker";

export function* handlePrintWorker({ startDate, endDate, docType }) {
    yield put(toggleModalState());
    yield put(toggleIsModalLoading());
    const toSend = {
        document_type: docType,
        start_date: startDate,
        end_date: endDate,
    };
    try {
        const response = yield call(apiCall, "POST", printerUrl, {
            data: toSend,
            responseType: "blob",
        });
        const fname = `${moment(startDate).format("YYYY-MM-DD_HH-mm")}_to_${moment(endDate).format(
            "YYYY-MM-DD_HH-mm",
        )}_rezipt_${docType}`;
        FileDownload(response, docType === "audit_report" ? `${fname}.csv` : `${fname}.zip`);
        return yield all([put(toggleModalState()), put(toggleIsModalLoading())]);
    } catch ({ response }) {
        if (response.status !== 200) {
            response.data.text().then((error) => AlertMessage("error", error));
        } else {
            AlertMessage("error", response.data.message);
        }
        return yield all([put(toggleModalState()), put(toggleIsModalLoading())]);
    }
}

export default function* reportsWatcher() {
    yield takeLatest(HANDLE_PRINT, handlePrintWorker);
}
