import React from "react";
import { message, Spin } from "antd";

const AlertLoading = (loading, data) => {
    message.config({
        top: 100,
    });

    return loading ? (
        <Spin spinning={loading} delay={1000}>
            {message.loading({ content: data })}
        </Spin>
    ) : (
        message.success(data)
    );
    // return message.loading({ content: data})
};

export default AlertLoading;
