import { fromJS, List } from "immutable";
import moment from "moment";
import _ from "lodash";
import {
    GET_PLATFORMS_SUCCESS,
    GET_PLATFORMS_FAILED,
    HANDLE_PRINT_SUCCESS,
    HANDLE_PRINT_FAILED,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    CLEAR_REPORTS,
    REPORT_CHANGE,
    TOGGLE_FORM_OPEN,
    UPDATE_DATE_RANGE,
    HANDLE_SELECT_MASTER,
    HANDLE_SELECT_ONE,
    UPDATE_SELECT_MASTER,
    FILENAME_POPUP,
    DL_WITH_DATE,
    UPDATE_SELECTED_SHOPS,
    CLEAR_SELECTED_SHOPS,
    GET_FILTERED_PLATFORMS,
    CLEAR_SELECTED_REPORTS,
    DOWNLOAD_AS_ONE_FILE,
} from "./constants";

export const initState = fromJS({
    reports: [],
    filteredReports: [],
    err: null,
    selectedReport: [],
    selectAllMaster: false,
    selectedShops: [],
    tableControls: {
        current_page: 1,
        sort_type: -1,
        limit: 10000,
        name: "",
        unique_platform_id: "",
        shop_url: "",
        shop_name: "",
        platform_order_id: "",
        shipping_type: "",
        status: "",
        shop_status: "Active",
    },
    startEndDate: {
        start_date: moment(new Date()).set({ hour: 0, minute: 0, second: 0 }).format("YYYY-MM-DD HH:mm"),
        end_date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
    },
    total_entries: 0,
    pdfResults: "",
    isModalOpen: false,
    isTableLoading: false,
    isModalLoading: false,
    isFormOpen: false,
    isFNModalOpen: false,
    isDLWithDate: false,
    dlAs1File: false,
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

// set reports
export const setPlatforms = (state, action) =>
    state
        .set("reports", action.data)
        .set("total_entries", action.totalEntries)
        .update("tableControls", () => action.tableControls)
        .update("reports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");
            selectedReport.size !== 0 &&
                iArray.map((i) => _.includes(selectedReport, i._id) && Object.assign(i, { selected: true }));

            return List(iArray);
        });

const objectAssign = (selected, item) => {
    _.includes(selected, item._id) && Object.assign(item, { selected: true });
};

export const setFilteredPlatforms = (state, action) =>
    state
        .update("filteredReports", () => {
            const selectedShops = state.get("selectedShops");
            const filtered = action.data.filter((item) => {
                const reg = new RegExp(
                    // eslint-disable-next-line prefer-template
                    ".*" + selectedShops.join("|") + ".*",
                    "g",
                );
                const regxRes = reg.test(item.shop_name);

                return regxRes && item;
            });

            return filtered;
        })
        .update("filteredReports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");

            selectedReport.size !== 0
                ? iArray.map((i) => objectAssign(selectedReport, i))
                : iArray.map((i) => Object.assign(i, { selected: false }));

            return List(iArray);
        });

export const setReportChange = (state, action) =>
    state.update("reports", (arr) => {
        const reports = List(arr).toJS();
        const newArray = reports.map((item) =>
            item._id === action._id ? Object.assign(item, { selected_report: action.value }) : item,
        );

        return List(newArray);
    });

export const setPDFResults = (state, action) => state.set("pdfResults", action.data);

// clear DATAS
export const clearReportsState = (state) =>
    state
        .update("reports", () => initState.get("reports"))
        .update("tableControls", () => initState.get("tableControls"))
        .update("selectedReport", () => initState.get("selectedReport"))
        .update("filteredReports", () => initState.get("filteredReports"))
        .update("startEndDate", () => initState.get("startEndDate"))
        .update("selectedShops", () => initState.get("selectedShops"));

export const clearSelectedReportsState = (state) =>
    state
        .set("selectAllMaster", false)
        .update("selectedReport", () => initState.get("selectedReport"))
        .update("filteredReports", (arr) => {
            const iArray = List(arr);

            const newArray = iArray.toJS();
            newArray.map((i) => Object.assign(i, { selected: false }));
            return List(newArray);
        });

//
export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

// table loading
export const toggleTableLoadingState = (state) => state.update("isTableLoading", (loading) => !loading);

// print loading
export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

export const clearSelectedShopsState = (state) => state.update("selectedShops", () => initState.get("selectedShops"));

// update filters and sorts
export const updateDropdowns = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateInputs = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateMultiSelect = (state, action) =>
    state
        .update("selectedShops", () => action.value)
        .update("filteredReports", () => {
            const selectedShops = action.value;
            const reports = state.get("reports");
            const filtered = reports.filter((item) => {
                const reg = new RegExp(
                    // eslint-disable-next-line prefer-template
                    ".*" + selectedShops.join("|") + ".*",
                    "g",
                );
                const regxRes = reg.test(item.shop_name);

                return regxRes && item;
            });

            return filtered;
        })
        .update("filteredReports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");

            selectedReport.size !== 0
                ? iArray.map((i) => objectAssign(selectedReport, i))
                : iArray.map((i) => Object.assign(i, { selected: false }));

            return List(iArray);
        });

export const toggleFormOpenState = (state) => state.update("isFormOpen", (isOpen) => !isOpen);

export const updateDateRange = (state, action) => state.update("startEndDate", (data) => data.merge(action.data));

export const isDLWithDateState = (state, action) => state.update("isDLWithDate", () => action.value);

// filename modal
export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

export const downloadAs1FileState = (state, action) => state.update("dlAs1File", () => action.value);

// FOR CHECKBOXES
export const handleSelectOne = (state, action) =>
    state
        .updateIn(["filteredReports", action.parentIndex, "selected"], (val) => !val)
        .update("selectedReport", (arr) => {
            const iArray = List(arr);
            const isSelected = state.getIn(["filteredReports", action.parentIndex, "selected"], (val) => val);

            const isSelectedUpdate = _.isFunction(isSelected) || !isSelected;

            const newArray = iArray.toJS();
            if (isSelectedUpdate) {
                newArray.push(action.data);
            } else if (!isSelectedUpdate && newArray.length > 0) {
                newArray.splice(newArray.indexOf(action.data), 1);
            }

            return newArray;
        });

export const handleSelectMaster = (state) =>
    state
        .update("selectAllMaster", (val) => !val)
        .update("filteredReports", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");
            const newArray = iArray;
            newArray.map((i) => Object.assign(i, { selected: !!selectAllMaster }));

            return newArray;
        })
        .update("selectedReport", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");
            const reports = state.get("filteredReports").toJS();

            const newArray = iArray.toJS();

            // eslint-disable-next-line array-callback-return
            reports.map((i) => {
                const idIdx = _.findIndex(newArray, i._id);
                selectAllMaster
                    ? idIdx === -1 && !_.includes(newArray, i._id) && newArray.push(i._id)
                    : _.remove(newArray, (item) => item === i._id);
            });

            return newArray;
        });

export const updateSelectMaster = (state) =>
    state.update("selectAllMaster", () => {
        const reports = state.get("filteredReports").toJS();
        const selectedReport = state.get("selectedReport");
        const selectedArr = [];
        reports.map((i) => selectedReport.size !== 0 && _.includes(selectedReport, i._id) && selectedArr.push(i));

        return selectedArr.length === reports.length && reports.length !== 0;
    });

export default function camsReportsReducer(state = initState, action) {
    switch (action.type) {
        case GET_PLATFORMS_SUCCESS:
            return setPlatforms(state, action);

        case GET_PLATFORMS_FAILED:
            return setError(state, action.err);

        case HANDLE_PRINT_SUCCESS:
            return setPDFResults(state, action);

        case HANDLE_PRINT_FAILED:
            return setError(state, action.err);

        case TOGGLE_TABLE_LOADING:
            return toggleTableLoadingState(state);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case UPDATE_DROPDOWNS:
            return updateDropdowns(state, action);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case CLEAR_REPORTS:
            return clearReportsState(state, action);

        case REPORT_CHANGE:
            return setReportChange(state, action);

        case TOGGLE_FORM_OPEN:
            return toggleFormOpenState(state);

        case UPDATE_DATE_RANGE:
            return updateDateRange(state, action);

        case HANDLE_SELECT_MASTER:
            return handleSelectMaster(state);

        case HANDLE_SELECT_ONE:
            return handleSelectOne(state, action);

        case UPDATE_SELECT_MASTER:
            return updateSelectMaster(state);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case DL_WITH_DATE:
            return isDLWithDateState(state, action);

        case UPDATE_SELECTED_SHOPS:
            return updateMultiSelect(state, action);

        case CLEAR_SELECTED_SHOPS:
            return clearSelectedShopsState(state);

        case GET_FILTERED_PLATFORMS:
            return setFilteredPlatforms(state, action);

        case CLEAR_SELECTED_REPORTS:
            return clearSelectedReportsState(state);

        case DOWNLOAD_AS_ONE_FILE:
            return downloadAs1FileState(state, action);

        default:
            return state;
    }
}
