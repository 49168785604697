import { fromJS } from "immutable";
import {
    IMPORT_PO,
    TOGGLE_FORM_OPEN,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    UPDATE_INPUTS,
    UPDATE_DATE,
    UPDATE_DROPDOWNS,
    UPDATE_CHECKBOX,
    CLEAR_EFFIE,
    SAVE_FILE,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
    CLEAR_AFTER_PRINT,
} from "./constants";

export const initState = fromJS({
    purchaseOrders: [],
    businessDetails: {
        customer_name: "Lazada E-Services Philippines, Inc.",
        registered_address:
            "23F Net Park, 5th Ave, E-Square Crescent Park West, Bonifacio Global City, Taguig City 1630, Philippines",
        business_style_or_name: "",
        terms: "Due in 30 days",
        tax_identification_number: "008-220-457-00000",
        delivery_address: "Same as address",
        date: new Date(),
        purchase_order_number: true,
        paper_type: false,
        document_type: "sales_invoice",
        sales_invoice_number: "",
        delivery_receipt_number: "",
    },
    err: null,
    isTableLoading: false,
    isModalLoading: false,
    isModalOpen: false,
    isFormOpen: false,
    pdfResults: "",
    file: "",
    isFNModalOpen: false,
    zipResults: "",
});

export const setError = (state, err) => state.set("err", err);

export const setPO = (state, action) => state.set("purchaseOrders", action.data);

export const setFile = (state, action) => state.set("file", action.file);

// clear DATAS
export const clearEffieState = (state) =>
    state
        .update("purchaseOrders", () => initState.get("purchaseOrders"))
        .update("pdfResults", () => initState.get("pdfResults"))
        .update("businessDetails", () => initState.get("businessDetails"))
        .update("file", () => initState.get("file"));

export const clearAfterPrintState = (state) =>
    state
        .update("pdfResults", () => initState.get("pdfResults"))
        .update("zipResults", () => initState.get("zipResults"));

export const toggleFormOpenState = (state) => state.update("isFormOpen", (isOpen) => !isOpen);

export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

export const setPDFResults = (state, action) => state.set("pdfResults", action.data);

export const setZipResult = (state, action) => state.set("zipResults", action.data);

// update header form
export const updateDropdowns = (state, action) => state.updateIn(["businessDetails", action.name], () => action.value);

export const updateInputs = (state, action) => state.updateIn(["businessDetails", action.name], () => action.value);

export const updateDate = (state, action) => state.updateIn(["businessDetails", action.name], () => action.value);

export const updateCheckbox = (state, action) => state.updateIn(["businessDetails", action.name], (val) => !val);

export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

export default function effieReducer(state = initState, action) {
    switch (action.type) {
        case IMPORT_PO:
            return setPO(state, action);

        case SAVE_FILE:
            return setFile(state, action);

        case TOGGLE_FORM_OPEN:
            return toggleFormOpenState(state);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case PRINT_ONE_ROW_SUCCESS:
            return setPDFResults(state, action);

        case PRINT_ONE_ROW_FAILED:
            return setError(state, action.err);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case UPDATE_DROPDOWNS:
            return updateDropdowns(state, action);

        case UPDATE_DATE:
            return updateDate(state, action);

        case UPDATE_CHECKBOX:
            return updateCheckbox(state, action);

        case CLEAR_EFFIE:
            return clearEffieState(state);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case SET_ZIP_RESULT:
            return setZipResult(state, action);

        case CLEAR_AFTER_PRINT:
            return clearAfterPrintState(state);

        default:
            return state;
    }
}
