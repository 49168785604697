import {
    FETCH_FILES,
    FETCH_FILES_SUCCESS,
    FETCH_FILES_FAILED,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    PRINT_ONE_ROW,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    CLEAR_FILES,
    HANDLE_SELECT_MASTER,
    HANDLE_SELECT_ONE,
    UPDATE_SELECT_MASTER,
    HANDLE_DESELECT_ALL,
    HANDLE_DL_FILES,
    GET_ALL_IDS,
    FILENAME_POPUP,
    IS_ALL_FILES,
    UPDATE_DATE_RANGE,
    CLEAR_ALL_FILES_STATE,
    VALUED_TABLE_LOADING,
} from "./constants";

export const fetchFiles = (tableControls = {}) => ({
    type: FETCH_FILES,
    tableControls,
});

export const fetchFilesSuccess = (data, tableControls, totalEntries) => ({
    type: FETCH_FILES_SUCCESS,
    data,
    tableControls,
    totalEntries,
});

export const toggleIsTableLoading = () => ({
    type: TOGGLE_TABLE_LOADING,
});

export const valuedIsTableLoading = (value) => ({
    type: VALUED_TABLE_LOADING,
    value,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const fetchFilesFailed = (err) => ({
    type: FETCH_FILES_FAILED,
    err,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const printOneRow = (selected) => ({
    type: PRINT_ONE_ROW,
    selected,
});

export const printOneRowSuccess = (data) => ({
    type: PRINT_ONE_ROW_SUCCESS,
    data,
});

export const printOneRowFailed = (err) => ({
    type: PRINT_ONE_ROW_FAILED,
    err,
});

export const clearFiles = () => ({
    type: CLEAR_FILES,
});

// for zipfile generation

export const handleSelectOne = (data = {}, parentIndex) => ({
    type: HANDLE_SELECT_ONE,
    data,
    parentIndex,
});

export const handleSelectMaster = () => ({
    type: HANDLE_SELECT_MASTER,
});

export const updateSelectMaster = () => ({
    type: UPDATE_SELECT_MASTER,
});

export const handleDeselectAll = () => ({
    type: HANDLE_DESELECT_ALL,
});

export const handleDLFiles = (ids, filename) => ({
    type: HANDLE_DL_FILES,
    ids,
    filename,
});

export const getAllIDs = (ids) => ({
    type: GET_ALL_IDS,
    ids,
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const handleIsAllFiles = (value) => ({
    type: IS_ALL_FILES,
    value,
});

export const updateDateRange = (names, values) => ({
    type: UPDATE_DATE_RANGE,
    data: {
        [`${names[0]}`]: values[0],
        [`${names[1]}`]: values[1],
    },
});

export const clearAllFilesState = () => ({
    type: CLEAR_ALL_FILES_STATE,
});
