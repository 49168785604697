import { takeLatest, put, call, select } from "redux-saga/effects";
import moment from "moment";
import FileDownload from "js-file-download";
import { List, Map } from "immutable";
import _ from "lodash";
import qs from "query-string";
import apiCall from "../../utils/ApiService";

import AlertMessage from "../../components/AlertMessage";

import {
    fetchFilesSuccess,
    fetchFilesFailed,
    toggleIsTableLoading,
    toggleModalState,
    toggleIsModalLoading,
    printOneRowSuccess,
    getAllIDs,
} from "./actions";

import { FETCH_FILES, PRINT_ONE_ROW, HANDLE_DL_FILES } from "./constants";

import { makeSelectTableControls } from "./selectors";

const filesURL = "/file";

export function* fetchFilesWorker({ tableControls }) {
    const curTableControls = yield select(makeSelectTableControls);

    const mergeTableControls = _.pickBy({
        ...curTableControls,
        ...tableControls,
    });

    const serializedTableControls = qs.stringify(mergeTableControls);

    try {
        yield put(toggleIsTableLoading());
        const { data, current_page, total_entries } = yield call(
            apiCall,
            "GET",
            `${filesURL}?${serializedTableControls}`,
        );

        yield put(fetchFilesSuccess(List(data), Map({ ...mergeTableControls, current_page }), total_entries));
        yield put(getAllIDs(data.map((item) => item.id)));
        yield put(toggleIsTableLoading());
    } catch ({ response }) {
        if (response) {
            const { data } = response;
            yield put(fetchFilesFailed(data.error));

            AlertMessage("error", response.data.message);
        }
    }
}

export function* printOneRow({ selected }) {
    try {
        yield put(toggleModalState());
        yield put(toggleIsModalLoading());

        const response = yield call(apiCall, "GET", `/file/${selected}`, {
            responseType: "blob",
            params: { get_actual_file: true },
        });

        const newBlob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);

        yield put(printOneRowSuccess(url));
    } catch ({ response }) {
        AlertMessage("error", "Print Failed");
        yield put(toggleModalState());
    }
    yield put(toggleIsModalLoading());
}

export function* handleDLFilesWorker({ ids, filename }) {
    try {
        const response = yield call(apiCall, "POST", "/file", {
            data: { ids, filename },
            responseType: "blob",
        });

        FileDownload(
            response,
            `${filename || `${moment(new Date()).format("YYYY-MM-DD_HH-mm-ss")}_rezipt_report`}.zip`,
        );
    } catch (error) {
        AlertMessage("error", "Downloading file(s) failed!");
    }
}

export default function* filesWatcher() {
    yield takeLatest(FETCH_FILES, fetchFilesWorker);
    yield takeLatest(PRINT_ONE_ROW, printOneRow);
    yield takeLatest(HANDLE_DL_FILES, handleDLFilesWorker);
}
