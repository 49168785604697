export const GET_PLATFORMS = "Reports/GET_PLATFORMS";
export const GET_PLATFORMS_SUCCESS = "Reports/GET_PLATFORMS_SUCCESS";
export const GET_PLATFORMS_FAILED = "Reports/GET_PLATFORMS_FAILED";

export const HANDLE_PRINT = "Reports/HANDLE_PRINT";
export const HANDLE_PRINT_SUCCESS = "Reports/HANDLE_PRINT_SUCCESS";
export const HANDLE_PRINT_FAILED = "Reports/HANDLE_PRINT_FAILED";

export const TOGGLE_TABLE_LOADING = "Reports/TOGGLE_TABLE_LOADING";
export const TOGGLE_MODAL = "Reports/TOGGLE_MODAL";
export const TOGGLE_MODAL_LOADING = "Reports/TOGGLE_MODAL_LOADING";

export const UPDATE_DROPDOWNS = "Reports/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "Reports/UPDATE_INPUTS";
export const UPDATE_DATE_RANGE = "Reports/UPDATE_DATE_RANGE";

export const CLEAR_REPORTS = "Reports/CLEAR_REPORTS";
export const REPORT_CHANGE = "Reports/REPORT_CHANGE";

export const FILENAME_POPUP = "Reports/FILENAME_POPUP";
export const SET_ZIP_RESULT = "Reports/SET_ZIP_RESULT";
