import {
    GET_PLATFORMS,
    GET_PLATFORMS_FAILED,
    GET_PLATFORMS_SUCCESS,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    CLEAR_REPORTS,
    REPORT_CHANGE,
    HANDLE_SELECT_ONE,
    FILENAME_POPUP,
    HANDLE_UPDATE_SHOP,
    UPDATE_SELECTED_SHOPS,
    CLEAR_SELECTED_SHOPS,
    GET_FILTERED_PLATFORMS,
    GET_LOADED,
    HANDLE_SELECT_FETCH,
} from "./constants";

export const getPlatforms = (tableControls = {}) => ({
    type: GET_PLATFORMS,
    tableControls,
});

export const getPlatformsSuccess = (data, tableControls, totalEntries) => ({
    type: GET_PLATFORMS_SUCCESS,
    data,
    tableControls,
    totalEntries,
});

export const getPlatformsFailed = (err) => ({
    type: GET_PLATFORMS_FAILED,
    err,
});

export const clearReports = () => ({
    type: CLEAR_REPORTS,
});

export const toggleIsTableLoading = () => ({
    type: TOGGLE_TABLE_LOADING,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const reportChange = (_id, value) => ({
    type: REPORT_CHANGE,
    _id,
    value,
});

export const handleSelectOne = (data = {}) => ({
    type: HANDLE_SELECT_ONE,
    data,
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const handleUpdateShop = (shop) => ({
    type: HANDLE_UPDATE_SHOP,
    shop,
});

export const updateSelectedShops = (value) => ({
    type: UPDATE_SELECTED_SHOPS,
    value,
});

export const clearSelectedShops = () => ({
    type: CLEAR_SELECTED_SHOPS,
});

export const getFilteredPlatforms = (data) => ({
    type: GET_FILTERED_PLATFORMS,
    data,
});

export const getLoaded = (loaded) => ({
    type: GET_LOADED,
    loaded,
});

export const handleSelectFetch = () => ({
    type: HANDLE_SELECT_FETCH,
});
