import {
    IMPORT_PO,
    TOGGLE_FORM_OPEN,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    PRINT_ONE_ROW,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    UPDATE_DATE,
    UPDATE_CHECKBOX,
    CLEAR_EFFIE,
    SAVE_FILE,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
    CLEAR_AFTER_PRINT,
} from "./constants";

export const importPO = (data) => ({
    type: IMPORT_PO,
    data,
});

export const saveFile = (file) => ({
    type: SAVE_FILE,
    file,
});

export const toggleFormOpen = () => ({
    type: TOGGLE_FORM_OPEN,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const printOneRow = () => ({
    type: PRINT_ONE_ROW,
});

export const printOneRowSuccess = (data) => ({
    type: PRINT_ONE_ROW_SUCCESS,
    data,
});

export const printOneRowFailed = (err) => ({
    type: PRINT_ONE_ROW_FAILED,
    err,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const updateDate = (name, value) => ({
    type: UPDATE_DATE,
    name,
    value,
});

export const updateCheckbox = (name) => ({
    type: UPDATE_CHECKBOX,
    name,
});

export const clearEffie = () => ({
    type: CLEAR_EFFIE,
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const setZipResult = (data) => ({
    type: SET_ZIP_RESULT,
    data,
});

export const clearAfterPrint = () => ({
    type: CLEAR_AFTER_PRINT,
});
