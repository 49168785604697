import { createSelector } from "reselect";

export const ordersSelector = (state) => state.get("ordersReducer");

export const makeSelectOrders = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("orders") ? ordersState.get("orders").toJS() : [],
);

export const makeSelectSearchOptions = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("searchOptions"),
);

export const makeSelectIsTableLoading = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("isTableLoading"),
);

export const makeSelectIsModalLoading = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("isModalLoading"),
);

export const makeSelectFirstLoad = createSelector(ordersSelector, (ordersState) => ordersState.get("isFirstLoad"));

export const makeSelectTableControls = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("tableControls").toJS(),
);

export const makeSelectTotalItems = createSelector(ordersSelector, (ordersState) => ordersState.get("total_entries"));

export const makeSelectModalState = createSelector(ordersSelector, (ordersState) => ordersState.get("isModalOpen"));

export const makeSelectPDFResults = createSelector(ordersSelector, (ordersState) => ordersState.get("pdfResults"));

export const makeSelectSelectedOrders = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("selectedOrders").size > 0 ? ordersState.get("selectedOrders").toJS() : [],
);

export const makeSelectSelectAll = createSelector(ordersSelector, (ordersState) => ordersState.get("selectAllMaster"));

export const makeSelectFNModalState = createSelector(ordersSelector, (ordersState) => ordersState.get("isFNModalOpen"));

export const makeSelectZIPResults = createSelector(ordersSelector, (ordersState) => ordersState.get("zipResults"));

export const makeSelectSelectedOrdersDisplay = createSelector(ordersSelector, (ordersState) =>
    ordersState.get("selectedOrdersDisplay").size > 0 ? ordersState.get("selectedOrdersDisplay").toJS() : [],
);
