import { fromJS } from "immutable";

import { IMPORT_REMITTANCE, SAVE_FILE, CLEAR_REMITTANCE } from "./constants";

export const initState = fromJS({
    remittance: [],
    err: null,
    isTableLoading: false,
    isModalLoading: false,
    isModalOpen: false,
    file: "",
});

export const setRemittance = (state, action) => state.set("remittance", action.data);

export const setFile = (state, action) => state.set("file", action.file);

export const clearRemittanceState = (state) =>
    state.update("remittance", () => initState.get("remittance")).update("file", () => initState.get("file"));

export default function remittanceReducer(state = initState, action) {
    switch (action.type) {
        case IMPORT_REMITTANCE:
            return setRemittance(state, action);

        case SAVE_FILE:
            return setFile(state, action);

        case CLEAR_REMITTANCE:
            return clearRemittanceState(state);

        default:
            return state;
    }
}
