import { createSelector } from "reselect";

export const effieSelector = (state) => state.get("effieReducer");

export const makeSelectPO = createSelector(effieSelector, (effieState) =>
    effieState.get("purchaseOrders") ? effieState.get("purchaseOrders") : [],
);

export const makeSelectFile = createSelector(effieSelector, (effieState) =>
    effieState.get("file") ? effieState.get("file") : {},
);

export const makeSelectFormOpenState = createSelector(effieSelector, (effieState) => effieState.get("isFormOpen"));

export const makeSelectModalState = createSelector(effieSelector, (effieState) => effieState.get("isModalOpen"));

export const makeSelectIsModalLoading = createSelector(effieSelector, (effieState) => effieState.get("isModalLoading"));

export const makeSelectPDFResults = createSelector(effieSelector, (effieState) => effieState.get("pdfResults"));

export const makeSelectZIPResults = createSelector(effieSelector, (effieState) => effieState.get("zipResults"));

export const makeSelectExtType = createSelector(effieSelector, (effieState) => effieState.get("extType"));

export const makeSelectBusinessDetails = createSelector(effieSelector, (effieState) =>
    effieState.get("businessDetails").toJS(),
);

export const makeSelectFNModalState = createSelector(effieSelector, (effieState) => effieState.get("isFNModalOpen"));
