import { takeLatest, call } from "redux-saga/effects";
import FileDownload from "js-file-download";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { SAVE_REMITTANCE } from "./constants";

export function* saveRemittanceWorker({ selected }) {
    try {
        const response = yield call(apiCall, "POST", `/shopify/generate-report`, {
            data: {
                remit_data: selected,
            },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });

        FileDownload(response, "remittance_report.csv");
    } catch ({ response }) {
        AlertMessage("error", "Save Failed");
    }
}

export default function* remittanceWatcher() {
    yield takeLatest(SAVE_REMITTANCE, saveRemittanceWorker);
}
