import { fromJS } from "immutable";
import { VERIFY_SUCCESS, VERIFY_FAILED, EDITSHOP_SUCCESS, EDITSHOP_FAILED, CLEAR_VERIFY } from "./constants";

export const initState = fromJS({
    currentShop: {},
});

export default function verifyShop(state = initState, action) {
    switch (action.type) {
        case VERIFY_FAILED:
            return state.set("err", action.err);

        case VERIFY_SUCCESS:
            return state.set("currentShop", action.currentShop);

        case EDITSHOP_FAILED:
            return state.set("err", action.err);

        case EDITSHOP_SUCCESS:
            return state.set("currentShop", initState.currentShop);

        case CLEAR_VERIFY:
            return state.set("currentShop", initState.currentShop);

        default:
            return state;
    }
}
