import {
    FETCH_ACCOUNT,
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNT_FAILED,
    CLEAR_ACCOUNT,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    EDIT_ACCOUNT,
    EDIT_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_FAILED,
    GET_FILE,
    GET_FILE_FAILED,
    GET_FILE_SUCCESS,
    TOGGLE_UPLOADING,
} from "./constants";

export const fetchAccount = () => ({
    type: FETCH_ACCOUNT,
});

export const fetchAccountSuccess = (data) => ({
    type: FETCH_ACCOUNT_SUCCESS,
    data,
});

export const fetchAccountFailed = (err) => ({
    type: FETCH_ACCOUNT_FAILED,
    err,
});

export const clearAccount = () => ({
    type: CLEAR_ACCOUNT,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const toggleUploading = () => ({
    type: TOGGLE_UPLOADING,
});

export const editAccount = (id, editedAccount) => ({
    type: EDIT_ACCOUNT,
    id,
    editedAccount,
});

export const editAccountSuccess = (data) => ({
    type: EDIT_ACCOUNT_SUCCESS,
    data,
});

export const editAccountFailed = (err) => ({
    type: EDIT_ACCOUNT_FAILED,
    err,
});

export const getFile = (file) => ({
    type: GET_FILE,
    file,
});

export const getFileSuccess = (data) => ({
    type: GET_FILE_SUCCESS,
    data,
});

export const getFileFailed = (err) => ({
    type: GET_FILE_FAILED,
    err,
});
