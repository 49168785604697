export const GET_PLATFORMS = "ShopManager/GET_PLATFORMS";
export const GET_PLATFORMS_SUCCESS = "ShopManager/GET_PLATFORMS_SUCCESS";
export const GET_PLATFORMS_FAILED = "ShopManager/GET_PLATFORMS_FAILED";

export const TOGGLE_TABLE_LOADING = "ShopManager/TOGGLE_TABLE_LOADING";

export const UPDATE_DROPDOWNS = "ShopManager/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "ShopManager/UPDATE_INPUTS";

export const CLEAR_REPORTS = "ShopManager/CLEAR_REPORTS";
export const REPORT_CHANGE = "ShopManager/REPORT_CHANGE";

export const HANDLE_SELECT_ONE = "ShopManager/HANDLE_SELECT_ONE";
export const FILENAME_POPUP = "ShopManager/FILENAME_POPUP";
export const HANDLE_UPDATE_SHOP = "ShopManager/HANDLE_UPDATE_SHOP";

export const UPDATE_SELECTED_SHOPS = "ShopManager/UPDATE_SELECTED_SHOPS";

export const CLEAR_SELECTED_SHOPS = "ShopManager/CLEAR_SELECTED_SHOPS";
export const GET_FILTERED_PLATFORMS = "ShopManager/GET_FILTERED_PLATFORMS";

export const GET_LOADED = "ShopManager/GET_LOADED";

export const HANDLE_SELECT_FETCH = "ShopManager/HANDLE_SELECT_FETCH";
