import { takeLatest, call, put, select } from "redux-saga/effects";
import { List, Map } from "immutable";
import _ from "lodash";
import qs from "query-string";
import moment from "moment";
import FileDownload from "js-file-download";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { GET_PLATFORMS, HANDLE_PRINT, HANDLE_DL_FILES, HANDLE_SELECT_FETCH } from "./constants";
import {
    getPlatformsSuccess,
    getPlatformsFailed,
    handlePrintSuccess,
    handlePrintFailed,
    toggleModalState,
    toggleIsModalLoading,
    toggleIsTableLoading,
    getFilteredPlatforms,
    getLoaded,
    setDownloadRes,
    clearSelectedShops,
    getPlatforms,
} from "./actions";
import { makeSelectTableControls, makeSelectStartEndDate, makeSelectAs1File, makeSelectPlatforms } from "./selectors";
import MessageDownloading from "../../components/MessageDownloading";

const platformsUrl = "/platforms";
const printerUrl = "/printer";

export function* getPlatformsWorker({ tableControls }) {
    const curTableControls = yield select(makeSelectTableControls);

    const mergeTableControls = _.pickBy({
        ...curTableControls,
        ...tableControls,
    });
    const serializedTableControls = qs.stringify(mergeTableControls);

    yield put(toggleIsTableLoading());
    try {
        const { data, current_page, total_entries } = yield call(
            apiCall,
            "GET",
            `${platformsUrl}?${serializedTableControls}`,
        );
        yield put(getPlatformsSuccess(List(data), Map({ ...mergeTableControls, current_page }), total_entries));

        yield put(getFilteredPlatforms(List(data)));
    } catch (err) {
        yield put(getPlatformsFailed(err));
        const { response } = err;
        AlertMessage("error", response.data.message);
    }
    yield put(toggleIsTableLoading());
}

export function* handlePrintWorker({ platformId, docType }) {
    try {
        yield put(toggleModalState());
        yield put(toggleIsModalLoading());

        const serializedParams = qs.stringify({
            document_type: docType || "z_report",
        });

        const xURL = `${printerUrl}/${docType}/${platformId}`;
        const repURL = `${platformsUrl}/${platformId}/getLatestReports?${serializedParams}`;
        const resType = {
            responseType: "blob",
        };

        // const data =
        //     docType === "x-report"
        //         ? yield call(apiCall, "GET", xURL, resType)
        //         : yield call(apiCall, "GET", repURL, resType);

        const data = yield call(apiCall, "GET", docType === "x-report" ? xURL : repURL, resType);

        const newBlob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);
        yield put(handlePrintSuccess(url));
    } catch (err) {
        yield put(handlePrintFailed(err));
        const { response } = err;
        AlertMessage("error", response.data.message);
        yield put(toggleModalState());
    }
    yield put(toggleIsModalLoading());
}

export function* handleDLFilesWorker({ ids, filename }) {
    const getFilterData = _.pick(yield select(makeSelectTableControls), [
        "platform_order_id",
        "shipping_type",
        "status",
    ]);

    try {
        const dlAs1File = yield select(makeSelectAs1File);
        const dateQuery = yield select(makeSelectStartEndDate);
        const totalReports = yield select(makeSelectPlatforms);
        const mergedQueries = _.pickBy({
            ...dateQuery,
            ...getFilterData,
        });
        const serializedQueries = qs.stringify(mergedQueries);
        const response = yield call(
            apiCall,
            "POST",
            dlAs1File
                ? `reports/single-campaign-tracker?${serializedQueries}`
                : `reports/campaign-tracker?${serializedQueries}`,
            {
                data: {
                    platform_ids: totalReports.length !== ids.length ? ids : dlAs1File ? "All" : ids,
                },
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    const { loaded } = progressEvent;
                    getLoaded(loaded);
                },
            },
        );
        response && (yield put(setDownloadRes(true)));
        const fname =
            filename ||
            `${moment(new Date()).format("YYYY-MM-DD_HH-mm-ss")}_rezipt_campaign_tracker_report_from_ ${moment(
                dateQuery.start_date,
            ).format("YYYY-MM-DD HH:mm")}_to_ ${moment(dateQuery.end_date).format("YYYY-MM-DD HH:mm")}`;
        MessageDownloading(1, true, "success");
        FileDownload(response, dlAs1File ? `${fname}.csv` : `${fname}.zip`);
    } catch (error) {
        MessageDownloading(1, true, "failed");
    }
}

export function* handleSelectFetchWorker() {
    try {
        yield put(clearSelectedShops());
        yield put(getPlatforms());
    } catch (error) {
        AlertMessage("error", "Platform selection failed.");
    }
}

export default function* camsReportsWatcher() {
    yield takeLatest(GET_PLATFORMS, getPlatformsWorker);
    yield takeLatest(HANDLE_PRINT, handlePrintWorker);
    yield takeLatest(HANDLE_DL_FILES, handleDLFilesWorker);
    yield takeLatest(HANDLE_SELECT_FETCH, handleSelectFetchWorker);
}
