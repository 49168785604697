import {
    GET_PLATFORMS,
    GET_PLATFORMS_FAILED,
    GET_PLATFORMS_SUCCESS,
    HANDLE_PRINT,
    HANDLE_PRINT_SUCCESS,
    HANDLE_PRINT_FAILED,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    UPDATE_DATE_RANGE,
    CLEAR_REPORTS,
    REPORT_CHANGE,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
} from "./constants";

export const getPlatforms = (tableControls = {}) => ({
    type: GET_PLATFORMS,
    tableControls,
});

export const getPlatformsSuccess = (data, tableControls, totalEntries) => ({
    type: GET_PLATFORMS_SUCCESS,
    data,
    tableControls,
    totalEntries,
});

export const getPlatformsFailed = (err) => ({
    type: GET_PLATFORMS_FAILED,
    err,
});

export const clearReports = () => ({
    type: CLEAR_REPORTS,
});

export const handlePrint = (startDate, endDate, docType) => ({
    type: HANDLE_PRINT,
    startDate,
    endDate,
    docType,
});

export const handlePrintSuccess = (data) => ({
    type: HANDLE_PRINT_SUCCESS,
    data,
});

export const handlePrintFailed = (err) => ({
    type: HANDLE_PRINT_FAILED,
    err,
});

export const toggleIsTableLoading = () => ({
    type: TOGGLE_TABLE_LOADING,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const updateDropdowns = (name, value) => ({
    type: UPDATE_DROPDOWNS,
    name,
    value,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const reportChange = (id, value) => ({
    type: REPORT_CHANGE,
    id,
    value,
});

export const updateDateRange = (names, values) => ({
    type: UPDATE_DATE_RANGE,
    data: {
        [`${names[0]}`]: values[0],
        [`${names[1]}`]: values[1],
    },
});

export const filenamePopup = () => ({
    type: FILENAME_POPUP,
});

export const setZipResult = (data) => ({
    type: SET_ZIP_RESULT,
    data,
});
