import { createSelector } from "reselect";

export const reportsSelector = (state) => state.get("camsReportsReducer");

export const makeSelectPlatforms = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("reports").size > 0 ? reportsState.get("reports").toJS() : reportsState.get("reports"),
);

export const makeSelectTableControls = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("tableControls").toJS(),
);

export const makeSelectTotalItems = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("total_entries"),
);

export const makeSelectPDFResults = createSelector(reportsSelector, (reportsState) => reportsState.get("pdfResults"));

export const makeSelectIsTableLoading = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("isTableLoading"),
);

export const makeSelectIsModalLoading = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("isModalLoading"),
);

export const makeSelectModalState = createSelector(reportsSelector, (reportsState) => reportsState.get("isModalOpen"));

export const makeSelectFormOpenState = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("isFormOpen"),
);

export const makeSelectStartEndDate = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("startEndDate").toJS(),
);

export const makeSelectselectedKAMReport = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("selectedReport").length > 0 ? reportsState.get("selectedReport") : [],
);

export const makeSelectAllMaster = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("selectAllMaster"),
);

export const makeSelectFNModalState = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("isFNModalOpen"),
);

export const makeSelectDLWithDate = createSelector(reportsSelector, (reportsState) => reportsState.get("isDLWithDate"));

export const makeSelectSelectedShops = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("selectedShops").size === 0 ? [] : reportsState.get("selectedShops"),
);

export const makeSelectFilteredReports = createSelector(reportsSelector, (reportsState) =>
    reportsState.get("filteredReports").size > 0
        ? reportsState.get("filteredReports").toJS()
        : reportsState.get("filteredReports"),
);

export const makeSelectDownloadRes = createSelector(reportsSelector, (reportsState) => reportsState.get("downloadRes"));

export const makeSelectLoaded = createSelector(reportsSelector, (reportsState) => reportsState.get("loaded"));

export const makeSelectAs1File = createSelector(reportsSelector, (reportsState) => reportsState.get("dlAs1File"));
