import { fromJS } from "immutable";
import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT_SUCCESS, LOGOUT_FAILED, CLEAR_LOCALSTORAGE } from "./constants";
import { getCookie } from "../../utils/cookies";

const storedUser = localStorage.getItem("currentUser");

const userDefaultVal = {
    verified: "",
    role: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email_address: "",
    username: "",
    status: "",
    name: "",
    id: "",
};

export const initState = fromJS({
    isLogin: getCookie("accessToken") !== "",
    currentUser: storedUser !== "" ? JSON.parse(storedUser) : userDefaultVal,
});

export default function auth(state = initState, action) {
    switch (action.type) {
        case LOGOUT_FAILED:
            return state.set("err", action.err);

        case LOGOUT_SUCCESS:
            return state.set("isLogin", false).set("currentUser", userDefaultVal);

        case LOGIN_FAILED:
            return state.set("err", action.err);

        case LOGIN_SUCCESS:
            return state.set("isLogin", true).set("currentUser", action.currentUser);

        case CLEAR_LOCALSTORAGE:
            return state.set("currentUser", userDefaultVal);

        default:
            return state;
    }
}
