import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router";
import history from "./utils/history";

import auth from "./containers/Login/reducer";
import accountReducer from "./containers/Account/reducer";
import ordersReducer from "./containers/Orders/reducer";
import verifyShop from "./containers/Shopify/reducer";
import platformReducer from "./containers/Platforms/reducer";
import filesReducer from "./containers/Files/reducer";
import reportsReducer from "./containers/Reports/reducer";
import effieReducer from "./containers/Effie/reducer";
import remittanceReducer from "./containers/Remittance/reducer";
import camsReportsReducer from "./containers/ReportCampaignTracker/reducer";
import collectionReceiptReducer from "./containers/CollectionReceipt/reducer";
import shopManagerReducer from "./containers/ShopManager/reducer";

const rootReducer = combineReducers({
    router: connectRouter(history),
    auth,
    accountReducer,
    ordersReducer,
    verifyShop,
    platformReducer,
    filesReducer,
    reportsReducer,
    effieReducer,
    remittanceReducer,
    camsReportsReducer,
    collectionReceiptReducer,
    shopManagerReducer,
});

export default rootReducer;
