import { takeLatest, put, call } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { CREATE_ACCOUNT } from "./constants";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";

const accountsFormUrl = "/accounts";

export function* createAccountWorker({ accountVal, uploadedLogo, uploadedBri }) {
    try {
        const res = yield call(apiCall, "POST", `${accountsFormUrl}/`, {
            data: accountVal,
        });

        if (uploadedLogo.size) {
            yield call(apiCall, "POST", `${accountsFormUrl}/${res.id}/uploadFiles`, {
                data: uploadedLogo,
                headers: {
                    "Content-Type": "application/json",
                },
            });
        }

        if (uploadedBri.size) {
            yield call(apiCall, "POST", `${accountsFormUrl}/${res.id}/uploadFiles`, {
                data: uploadedBri,
                headers: {
                    "Content-Type": "application/json",
                },
            });
        }

        yield put(replace(accountsFormUrl));
        AlertMessage("success", "Creating account successful.");
    } catch (error) {
        AlertMessage("error", "Create failed!");
    }
}

export default function* accountFormWatcher() {
    yield takeLatest(CREATE_ACCOUNT, createAccountWorker);
}
