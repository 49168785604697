// Verify Shop
export const VERIFY = "Verify/VERIFY";
export const VERIFY_SUCCESS = "Verify/VERIFY_SUCCESS";
export const VERIFY_FAILED = "Verify/VERIFY_FAILED";

// Edit Shop
export const EDITSHOP = "Verify/EDITSHOP";
export const EDITSHOP_SUCCESS = "Verify/EDITSHOP_SUCCESS";
export const EDITSHOP_FAILED = "Verify/EDITSHOP_FAILED";
export const CLEAR_VERIFY = "Verify/CLEAR_VERIFY";
