import { fromJS, List } from "immutable";
import _ from "lodash";
import {
    FETCH_FILES_SUCCESS,
    FETCH_FILES_FAILED,
    TOGGLE_TABLE_LOADING,
    TOGGLE_MODAL_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    TOGGLE_MODAL,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    CLEAR_FILES,
    HANDLE_SELECT_MASTER,
    HANDLE_SELECT_ONE,
    GET_ALL_IDS,
    FILENAME_POPUP,
    IS_ALL_FILES,
    UPDATE_SELECT_MASTER,
    UPDATE_DATE_RANGE,
    CLEAR_ALL_FILES_STATE,
    VALUED_TABLE_LOADING,
} from "./constants";

export const initState = fromJS({
    files: [],
    selectAllMaster: false,
    selectedFiles: [],
    fileIDs: [],
    err: null,
    isTableLoading: false,
    isModalLoading: false,
    tableControls: {
        current_page: 1,
        limit: 10,
        sort_type: -1,
        filename: undefined,
        tags: undefined,
        start_date: new Date(new Date().setHours(0, 0, 0, 0)),
        end_date: new Date(),
    },
    total_entries: 0,
    pdfResults: "",
    isModalOpen: false,
    isFNModalOpen: false,
    isAllFiles: false,
});

export const setError = (state, err) => state.set("err", err);

export const setFiles = (state, action) =>
    state
        .set("files", action.data)
        .set("total_entries", action.totalEntries)
        .update("tableControls", () => action.tableControls)
        .update("files", (arr) => {
            const iArray = List(arr);
            const selectedFiles = state.get("selectedFiles");

            selectedFiles.size !== 0 &&
                iArray.map((i) => _.includes(selectedFiles, i.id) && Object.assign(i, { selected: true }));

            return List(iArray);
        });

// table loading
export const toggleTableLoadingState = (state) => state.update("isTableLoading", (loading) => !loading);

export const valuedTableLoadingState = (state, action) => state.update("isTableLoading", () => action.value);

// print loading
export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

// update filters and sorts
export const updateDropdowns = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateInputs = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

// set pdfResults
export const setPDFResults = (state, action) => state.set("pdfResults", action.data);

export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

// clear Files
export const clearFilesState = (state) => state.update("files", () => initState.get("files"));

export const clearAllFilesState = (state) =>
    state
        .update("files", () => initState.get("files"))
        .update("tableControls", () => initState.get("tableControls"))
        .update("selectAllMaster", () => initState.get("selectAllMaster"))
        .update("selectedFiles", () => initState.get("selectedFiles"))
        .update("filesIDs", () => initState.get("fileIDs"))
        .update("isFNModalOpen", () => initState.get("isFNModalOpen"))
        .update("isAllFiles", () => initState.get("isAllFiles"));

export const setAllIDs = (state, action) => state.update("fileIDs", () => action.ids);

export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

export const isAllFilesState = (state, action) => state.update("isAllFiles", () => action.value);

export const updateDateRange = (state, action) => state.update("tableControls", (data) => data.merge(action.data));

// FOR CHECKBOXES
export const handleSelectOne = (state, action) =>
    state
        .updateIn(["files", action.parentIndex, "selected"], (val) => !val)
        .update("selectedFiles", (arr) => {
            const iArray = List(arr);
            const isSelected = state.getIn(["files", action.parentIndex, "selected"], (val) => val);

            const isSelectedUpdate = _.isFunction(isSelected) || !isSelected;

            const newArray = iArray.toJS();
            if (isSelectedUpdate) {
                newArray.push(action.data);
            } else if (!isSelectedUpdate && newArray.length > 0) {
                newArray.splice(newArray.indexOf(action.data), 1);
            }

            return newArray;
        });

export const handleSelectMaster = (state) =>
    state
        .update("selectAllMaster", (val) => !val)
        .update("files", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");
            const newArray = iArray;
            newArray.map((i) => Object.assign(i, { selected: !!selectAllMaster }));

            return newArray;
        })
        .update("selectedFiles", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");
            const files = state.get("files").toJS();

            const newArray = iArray.toJS();

            // eslint-disable-next-line array-callback-return
            files.map((i) => {
                const idIdx = _.findIndex(newArray, i.id);
                selectAllMaster
                    ? idIdx === -1 && !_.includes(newArray, i.id) && newArray.push(i.id)
                    : _.remove(newArray, (item) => item === i.id);
            });

            return newArray;
        });

export const updateSelectMaster = (state) =>
    state.update("selectAllMaster", () => {
        const files = state.get("files").toJS();
        const selectedFiles = state.get("selectedFiles");
        const selectedArr = [];

        files.map((i) => selectedFiles.size !== 0 && _.includes(selectedFiles, i.id) && selectedArr.push(i));

        return selectedArr.length === files.length && files.length !== 0;
    });

export default function filesReducer(state = initState, action) {
    switch (action.type) {
        case FETCH_FILES_SUCCESS:
            return setFiles(state, action);

        case FETCH_FILES_FAILED:
            return setError(state, action.err);

        case CLEAR_FILES:
            return clearFilesState(state);

        case TOGGLE_TABLE_LOADING:
            return toggleTableLoadingState(state);

        case VALUED_TABLE_LOADING:
            return valuedTableLoadingState(state, action);

        case UPDATE_DROPDOWNS:
            return updateDropdowns(state, action);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case PRINT_ONE_ROW_SUCCESS:
            return setPDFResults(state, action);

        case PRINT_ONE_ROW_FAILED:
            return setError(state, action.err);

        case HANDLE_SELECT_MASTER:
            return handleSelectMaster(state);

        case HANDLE_SELECT_ONE:
            return handleSelectOne(state, action);

        case GET_ALL_IDS:
            return setAllIDs(state, action);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case IS_ALL_FILES:
            return isAllFilesState(state, action);

        case UPDATE_SELECT_MASTER:
            return updateSelectMaster(state);

        case UPDATE_DATE_RANGE:
            return updateDateRange(state, action);

        case CLEAR_ALL_FILES_STATE:
            return clearAllFilesState(state);

        default:
            return state;
    }
}
