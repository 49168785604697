import { IMPORT_REMITTANCE, SAVE_REMITTANCE, SAVE_FILE, CLEAR_REMITTANCE } from "./constants";

export const importRemittance = (data) => ({
    type: IMPORT_REMITTANCE,
    data,
});

export const saveRemittance = (selected) => ({
    type: SAVE_REMITTANCE,
    selected,
});

export const saveFile = (file) => ({
    type: SAVE_FILE,
    file,
});

export const clearRemittance = () => ({
    type: CLEAR_REMITTANCE,
});
