import { createSelector } from "reselect";

export const shopsSelector = (state) => state.get("shopManagerReducer");

export const makeSelectPlatforms = createSelector(shopsSelector, (shopsState) =>
    shopsState.get("reports").size > 0 ? shopsState.get("reports").toJS() : shopsState.get("reports"),
);

export const makeSelectTableControls = createSelector(shopsSelector, (shopsState) =>
    shopsState.get("tableControls").toJS(),
);

export const makeSelectTotalItems = createSelector(shopsSelector, (shopsState) => shopsState.get("total_entries"));

export const makeSelectIsTableLoading = createSelector(shopsSelector, (shopsState) => shopsState.get("isTableLoading"));

export const makeSelectselectedKAMReport = createSelector(shopsSelector, (shopsState) =>
    shopsState.get("selectedReport").length > 0 ? shopsState.get("selectedReport") : [],
);

export const makeSelectFNModalState = createSelector(shopsSelector, (shopsState) => shopsState.get("isFNModalOpen"));

export const makeSelectSelectedShops = createSelector(shopsSelector, (shopsState) =>
    shopsState.get("selectedShops").size === 0 ? [] : shopsState.get("selectedShops"),
);

export const makeSelectFilteredReports = createSelector(shopsSelector, (shopsState) =>
    shopsState.get("filteredReports").size > 0
        ? shopsState.get("filteredReports").toJS()
        : shopsState.get("filteredReports"),
);

export const makeSelectLoaded = createSelector(shopsSelector, (shopsState) => shopsState.get("loaded"));
