export const FETCH_FILES = "Files/FETCH_FILES";
export const FETCH_FILES_SUCCESS = "Files/FETCH_FILES_SUCCESS";
export const FETCH_FILES_FAILED = "Files/FETCH_FILES_FAILED";

export const TOGGLE_TABLE_LOADING = "Files/TOGGLE_TABLE_LOADING";
export const VALUED_TABLE_LOADING = "Files/VALUED_TABLE_LOADING";
export const TOGGLE_MODAL = "Files/TOGGLE_MODAL";
export const TOGGLE_MODAL_LOADING = "Files/TOGGLE_MODAL_LOADING";

export const UPDATE_DROPDOWNS = "Files/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "Files/UPDATE_INPUTS";

export const PRINT_ONE_ROW = "Files/PRINT_ONE_ROW";
export const PRINT_ONE_ROW_SUCCESS = "Files/PRINT_ONE_ROW_SUCCESS";
export const PRINT_ONE_ROW_FAILED = "Files/PRINT_ONE_ROW_FAILED";

export const CLEAR_FILES = "Files/CLEAR_FILES";
export const CLEAR_ALL_FILES_STATE = "Files/CLEAR_ALL_FILES_STATE";

export const HANDLE_SELECT_ONE = "Files/HANDLE_SELECT_ONE";
export const HANDLE_SELECT_MASTER = "Files/HANDLE_SELECT_MASTER";
export const HANDLE_DESELECT_ALL = "Files/HANDLE_DESELECT_ALL";

export const UPDATE_SELECT_MASTER = "Files/UPDATE_SELECT_MASTER";

export const HANDLE_DL_FILES = "Files/HANDLE_DL_FILES";
export const GET_ALL_IDS = "Files/GET_ALL_IDS";
export const FILENAME_POPUP = "Files/FILENAME_POPUP";
export const IS_ALL_FILES = "Files/IS_ALL_FILES";

export const UPDATE_DATE_RANGE = "Files/UPDATE_DATE_RANGE";
