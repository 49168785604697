export const IMPORT_CR = "CollectionReceipt/IMPORT_CR";
export const PRINT_COLLECTION_RECEIPT = "CollectionReceipt/PRINT_COLLECTION_RECEIPT";
export const CLEAR_COLLECTION_RECEIPT = "CollectionReceipt/CLEAR_COLLECTION_RECEIPT";
export const SAVE_FILE = "CollectionReceipt/SAVE_FILE";

export const TOGGLE_FORM_OPEN = "CollectionReceipt/TOGGLE_FORM_OPEN";
export const TOGGLE_MODAL = "CollectionReceipt/TOGGLE_MODAL";

export const TOGGLE_MODAL_LOADING = "CollectionReceipt/TOGGLE_MODAL_LOADING";

export const PRINT_ONE_ROW = "CollectionReceipt/PRINT_ONE_ROW";
export const PRINT_ONE_ROW_SUCCESS = "CollectionReceipt/PRINT_ONE_ROW_SUCCESS";
export const PRINT_ONE_ROW_FAILED = "CollectionReceipt/PRINT_ONE_ROW_FAILED";

export const UPDATE_INPUTS = "CollectionReceipt/UPDATE_INPUTS";

export const CLEAR_AFTER_PRINT = "Collection/CLEAR_AFTER_PRINT";
