export const IMPORT_PO = "Effie/IMPORT_PO";
export const SAVE_FILE = "Effie/SAVE_FILE";

export const TOGGLE_FORM_OPEN = "Effie/TOGGLE_FORM_OPEN";
export const TOGGLE_MODAL = "Effie/TOGGLE_MODAL";

export const TOGGLE_MODAL_LOADING = "Effie/TOGGLE_MODAL_LOADING";

export const PRINT_ONE_ROW = "Effie/PRINT_ONE_ROW";
export const PRINT_ONE_ROW_SUCCESS = "Effie/PRINT_ONE_ROW_SUCCESS";
export const PRINT_ONE_ROW_FAILED = "Effie/PRINT_ONE_ROW_FAILED";

export const UPDATE_DROPDOWNS = "Effie/UPDATE_DROPDOWNS";
export const UPDATE_INPUTS = "Effie/UPDATE_INPUTS";
export const UPDATE_DATE = "Effie/UPDATE_DATE";
export const UPDATE_CHECKBOX = "Effie/UPDATE_CHECKBOX";

export const CLEAR_EFFIE = "Effie/CLEAR_EFFIE";
export const CLEAR_AFTER_PRINT = "Effie/CLEAR_AFTER_PRINT";
export const FILENAME_POPUP = "Effie/FILENAME_POPUP";
export const SET_ZIP_RESULT = "Effie/SET_ZIP_RESULT";
