import { takeLatest, put, call, select, all } from "redux-saga/effects";
import FileType from "file-type";
import _ from "lodash";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { PRINT_ONE_ROW } from "./constants";
import {
    toggleModalState,
    toggleIsModalLoading,
    printOneRowSuccess,
    printOneRowFailed,
    setZipResult,
    filenamePopup,
} from "./actions";
import { makeSelectPO, makeSelectBusinessDetails } from "./selectors";

export function* printOneRowWorker() {
    try {
        const purchaseOrders = yield select(makeSelectPO);
        const businessDetails = yield select(makeSelectBusinessDetails);

        const perPO = _(purchaseOrders)
            .groupBy("purchase_order_number")
            .map((value, key) => ({
                purchase_order_number: key,
                purchase_items: value,
            }))
            .value();

        const translatedPO = perPO.map((data) => ({
            agreement_number: data.purchase_items[0].agreement_number,
            country: data.purchase_items[0].country,
            currency: data.purchase_items[0].currency,
            logistic_arrival_datetime: data.purchase_items[0].logistic_arrival_datetime,
            logistic_method: data.purchase_items[0].logistic_method,
            logistic_type: data.purchase_items[0].logistic_type,
            note_to_supplier: data.purchase_items[0].note_to_supplier,
            payment_terms: data.purchase_items[0].payment_terms,
            payment_method: data.purchase_items[0].payment_type,
            pieces_per_case: data.purchase_items[0].pc_case,
            purchase_order_number: data.purchase_order_number,
            supplier_address: data.purchase_items[0].supplier_address,
            supplier_contract_type: data.purchase_items[0].supplier_contract_type,
            supplier_name: data.purchase_items[0].supplier_name,
            warehouse_name: data.purchase_items[0].warehouse_name,
            purchase_items: data.purchase_items.map((item) => ({
                purchase_order_number: data.purchase_order_number,
                buying_unit: item.buying_unit,
                shop_sku: item.sku,
                name: item.product_name,
                sku: item.seller_sku,
                expiry_date: item.expirydate,
                unit_of_measurement: item.uom,
                cases: item.cases,
                pieces_per_case: item.pc_case,
                price_per_case: item.price___case,
                total_quantity: item.total_qty,
                buying_quantity: item.buying_quantity,
                accept_buying_quantity: item.accept_buying_quantity,
                receive_buying_quantity: item.receive_buying_quantity,
                receive_defective_quantity: item.receive_defective_quantity,
                price: item.negotiated_price,
                supplier_approval_status: item.supplier_approval_status,
                shop_name: item.brand,
                platform_category_name: item.category,
                color: item.color,
                size: item.size,
                item_price: item.retail_price,
                net_total: item.total_cost_exclude_vat,
                vat: item.vat,
                gross_total: item.total_cost_include_vat,
                // eslint-disable-next-line no-underscore-dangle
                percentage_discount: item._age_discount,
                less_discount: item.less__discount,
                vat_with_discount: item.vat_w__discount,
                total_amount_due: item.total_amount_due,
            })),
        }));

        const newTranslatedPO = translatedPO.map((data) =>
            Object.entries(data).reduce(
                // eslint-disable-next-line no-return-assign, no-param-reassign
                (a, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                {},
            ),
        );

        const paperType = businessDetails.paper_type ? "loose_leaf_format" : "computerized_format";

        const response = yield call(apiCall, "POST", `/purchase-orders/print/${paperType.replaceAll("_", "-")}`, {
            responseType: "arraybuffer",
            data: {
                purchase_order_data: newTranslatedPO,
                purchase_order_header: {
                    ...businessDetails,
                    paper_type: paperType,
                },
            },
        });

        const extType = yield FileType.fromBuffer(response);

        const newBlob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);

        if (extType && extType.ext !== "zip") {
            return yield all([
                put(toggleModalState()),
                put(toggleIsModalLoading()),
                put(printOneRowSuccess(url)),
                put(toggleIsModalLoading()),
            ]);
        }

        return yield all([put(setZipResult(response)), put(filenamePopup())]);
    } catch ({ response }) {
        AlertMessage("error", "Print Failed");
        yield printOneRowFailed(response.error);
        yield put(toggleModalState());
    }

    return 0;
}

export default function* effieWatcher() {
    yield takeLatest(PRINT_ONE_ROW, printOneRowWorker);
}
