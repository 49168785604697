import { fromJS, List } from "immutable";
import _ from "lodash";
import {
    GET_PLATFORMS_SUCCESS,
    GET_PLATFORMS_FAILED,
    TOGGLE_TABLE_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    CLEAR_REPORTS,
    REPORT_CHANGE,
    HANDLE_SELECT_ONE,
    FILENAME_POPUP,
    UPDATE_SELECTED_SHOPS,
    CLEAR_SELECTED_SHOPS,
    GET_FILTERED_PLATFORMS,
} from "./constants";

export const initState = fromJS({
    reports: [],
    filteredReports: [],
    err: null,
    selectedReport: [],
    selectedShops: [],
    tableControls: {
        current_page: 1,
        sort_type: -1,
        limit: 10000,
        name: "",
        unique_platform_id: "",
        shop_url: "",
        shop_name: "",
        platform_order_id: "",
        shipping_type: "",
        status: "",
        shop_status: "Active",
    },
    total_entries: 0,
    isTableLoading: false,
    isFNModalOpen: false,
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

// set reports
export const setPlatforms = (state, action) =>
    state
        .set("reports", action.data)
        .set("total_entries", action.totalEntries)
        .update("tableControls", () => action.tableControls)
        .update("reports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");

            selectedReport.size !== 0 &&
                iArray.map((i) => _.includes(selectedReport, i._id) && Object.assign(i, { selected: true }));

            return List(iArray);
        });

const objectAssign = (selected, item) => {
    _.includes(selected, item._id) && Object.assign(item, { selected: true });
};

export const setFilteredPlatforms = (state, action) =>
    state
        .update("filteredReports", () => {
            const selectedShops = state.get("selectedShops");
            const filtered = action.data.filter((item) => {
                const reg = new RegExp(
                    // eslint-disable-next-line prefer-template
                    ".*" + selectedShops.join("|") + ".*",
                    "g",
                );
                const regxRes = reg.test(item.shop_name);

                return regxRes && item;
            });

            return filtered;
        })
        .update("filteredReports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");

            selectedReport.size !== 0
                ? iArray.map((i) => objectAssign(selectedReport, i))
                : iArray.map((i) => Object.assign(i, { selected: false }));

            return List(iArray);
        });

export const setReportChange = (state, action) =>
    state.update("reports", (arr) => {
        const reports = List(arr).toJS();
        const newArray = reports.map((item) =>
            item._id === action._id ? Object.assign(item, { selected_report: action.value }) : item,
        );

        return List(newArray);
    });

// clear DATAS
export const clearReportsState = (state) =>
    state
        .update("reports", () => initState.get("reports"))
        .update("tableControls", () => initState.get("tableControls"))
        .update("selectedReport", () => initState.get("selectedReport"))
        .update("filteredReports", () => initState.get("filteredReports"))
        .update("selectedShops", () => initState.get("selectedShops"));

// table loading
export const toggleTableLoadingState = (state) => state.update("isTableLoading", (loading) => !loading);

export const clearSelectedShopsState = (state) => state.update("selectedShops", () => initState.get("selectedShops"));

// update filters and sorts
export const updateDropdowns = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateInputs = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateMultiSelect = (state, action) =>
    state
        .update("selectedShops", () => action.value)
        .update("filteredReports", () => {
            const selectedShops = action.value;
            const reports = state.get("reports");
            const filtered = reports.filter((item) => {
                const reg = new RegExp(
                    // eslint-disable-next-line prefer-template
                    ".*" + selectedShops.join("|") + ".*",
                    "g",
                );
                const regxRes = reg.test(item.shop_name);

                return regxRes && item;
            });

            return filtered;
        })
        .update("filteredReports", (arr) => {
            const iArray = List(arr);
            const selectedReport = state.get("selectedReport");

            selectedReport.size !== 0
                ? iArray.map((i) => objectAssign(selectedReport, i))
                : iArray.map((i) => Object.assign(i, { selected: false }));

            return List(iArray);
        });

// form modal
export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

// for active_dates
export const handleSelectOne = (state, action) =>
    state.update("selectedReport", () => {
        const newArray = action.data.slice();
        return newArray;
    });

export default function shopManagerReducer(state = initState, action) {
    switch (action.type) {
        case GET_PLATFORMS_SUCCESS:
            return setPlatforms(state, action);

        case GET_PLATFORMS_FAILED:
            return setError(state, action.err);

        case TOGGLE_TABLE_LOADING:
            return toggleTableLoadingState(state);

        case UPDATE_DROPDOWNS:
            return updateDropdowns(state, action);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case CLEAR_REPORTS:
            return clearReportsState(state, action);

        case REPORT_CHANGE:
            return setReportChange(state, action);

        case HANDLE_SELECT_ONE:
            return handleSelectOne(state, action);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case UPDATE_SELECTED_SHOPS:
            return updateMultiSelect(state, action);

        case CLEAR_SELECTED_SHOPS:
            return clearSelectedShopsState(state);

        case GET_FILTERED_PLATFORMS:
            return setFilteredPlatforms(state, action);

        default:
            return state;
    }
}
