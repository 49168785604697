/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable array-callback-return */
import { fromJS, List } from "immutable";
import _ from "lodash";
import {
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_FAILED,
    CLEAR_ORDERS,
    TOGGLE_TABLE_LOADING,
    TOGGLE_MODAL,
    PRINT_SELECTED_FAILED,
    PRINT_SELECTED_SUCCESS,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    FETCH_FILTER_OPTIONS_SUCCESS,
    FETCH_FILTER_OPTIONS_FAILED,
    HANDLE_SELECT_ONE,
    HANDLE_SELECT_PARENT,
    HANDLE_SELECT_MASTER,
    UPDATE_SELECT_MASTER,
    HANDLE_DESELECT_ALL,
    TOGGLE_MODAL_LOADING,
    UPDATE_DROPDOWNS,
    UPDATE_INPUTS,
    UPDATE_DATE_RANGE,
    CLEAR_ALL_ORDERS,
    TOGGLE_FIRST_LOAD,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
    CLEAR_AFTER_PRINT,
    SORT_CHANGE,
    UPDATE_SELECTED_ORDER,
    UPDATE_MASTER_SELECTED_ORDER,
    VALUED_TABLE_LOADING,
} from "./constants";

export const initState = fromJS({
    orders: [],
    selectAllMaster: false,
    selectedOrders: [],
    selectedOrdersDisplay: [],
    searchOptions: {
        platforms: ["All"],
        shop_names: ["All"],
    },
    err: null,
    isTableLoading: false,
    isModalLoading: false,
    tableControls: {
        current_page: 1,
        limit: 10,
        status: "delivered",
        sort_by: "platform_updated_at",
        sort_type: -1,
        platform: undefined,
        unique_platform_id: "",
        print_status: "never_been_printed",
        payment_method: undefined,
        platform_order_id: [],
        shop_sku: "",
        item_name: "",
        start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
        end_date: new Date(),
    },
    total_entries: 0,
    pdfResults: "",
    isModalOpen: false,
    isFirstLoad: true,
    isFNModalOpen: false,
    zipResults: "",
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

// fetch ORDERS
export const setOrders = (state, action) =>
    state
        .set("orders", action.data)
        .set("total_entries", action.totalEntries)
        .update("tableControls", () => action.tableControls)
        .update("orders", (arr) => {
            const iArray = List(arr);
            const selectedOrders = state.get("selectedOrders");

            selectedOrders.toJS().map((slctd) => {
                iArray.map((i) => {
                    i.items.map((j) => {
                        if (j.id === slctd.id && j.shop_sku === slctd.shop_sku) {
                            j["selected"] = true;
                        }
                    });
                });
            });

            iArray.map((i) => {
                const validPkgIds = [];
                const res = [];
                i.items.map((j) => {
                    if (j.platform_package_id) {
                        validPkgIds.push(j.platform_package_id);
                    }

                    if (j.selected) {
                        res.push(j.selected);
                    }
                });
                if (validPkgIds.length === res.length && validPkgIds.length !== 0) {
                    i["parent_selected"] = true;
                } else {
                    i["parent_selected"] = false;
                }
            });
            return List(iArray);
        });

// update select master
export const updateSelectMaster = (state) =>
    state.update("selectAllMaster", () => {
        const orders = state.get("orders").toJS();

        const validPkgIds = [];
        const isSelected = [];
        orders.map((i) => {
            i.items.map((j) => {
                if (j.platform_package_id) {
                    validPkgIds.push(j.platform_package_id);
                }

                if (j.selected) {
                    isSelected.push(j.selected);
                }
            });
        });

        if (validPkgIds.length === isSelected.length && validPkgIds.length !== 0) {
            return true;
        }

        return false;
    });

// fetch search options
export const setSearchOptions = (state, action) => state.set("searchOptions", action.data);

// sort change
export const updateSortChange = (state, action) =>
    state
        .updateIn(["tableControls", "sort_by"], () => action.sortBy)
        .updateIn(["tableControls", "sort_type"], () => action.sortType);

// set pdfResults
export const setPDFResults = (state, action) => state.set("pdfResults", action.data);

// clear DATAS
export const clearOrdersState = (state) => state.update("orders", () => initState.get("orders"));

export const clearAllOrdersState = (state) =>
    state.update("orders", () => initState.get("orders")).update("tableControls", () => initState.get("tableControls"));

// update filters and sorts
export const updateDropdowns = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateInputs = (state, action) => state.updateIn(["tableControls", action.name], () => action.value);

export const updateDateRange = (state, action) => state.update("tableControls", (data) => data.merge(action.data));

// table loading
export const toggleTableLoadingState = (state) => state.update("isTableLoading", (loading) => !loading);

export const valuedTableLoadingState = (state, action) => state.update("isTableLoading", () => action.value);

// print loading
export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

// firstload
export const toggleFirstLoad = (state) => state.update("isFirstLoad", (firstLoad) => !firstLoad);

//
export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

// for zip download
export const setZipResult = (state, action) => state.set("zipResults", action.data);

export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

export const clearAfterPrintState = (state) =>
    state
        .update("pdfResults", () => initState.get("pdfResults"))
        .update("zipResults", () => initState.get("zipResults"));

export const handleSelectOne = (state, action) =>
    state
        .updateIn(["orders", action.parentIndex, "items", action.index, "selected"], (val) => !val)
        .updateIn(["orders", action.parentIndex, "parent_selected"], () => {
            const iArray = state.getIn(["orders", action.parentIndex, "items"]);
            const newArray = [...iArray];
            const res = [];

            newArray.map((item) => {
                if (item.selected === true) {
                    res.push(item.selected);
                }
            });

            if (newArray.length !== res.length + 1) {
                return true;
            }
            return false;
        })
        .update("selectedOrders", (arr) => {
            const iArray = List(arr);
            const isSelected = state.getIn(
                ["orders", action.parentIndex, "items", action.index, "selected"],
                (val) => val,
            );

            let isSelectedUpdate = false;
            if (_.isFunction(isSelected)) {
                isSelectedUpdate = true;
            } else {
                isSelectedUpdate = !isSelected;
            }

            const newArray = iArray.toJS();
            if (isSelectedUpdate) {
                newArray.push(action.data);
            } else if (!isSelectedUpdate && newArray.length > 0) {
                newArray.splice(_.findIndex(newArray, action.data), 1);
            }
            return List(newArray);
        });

export const handleSelectParent = (state, action) =>
    state
        .updateIn(["orders", action.index, "parent_selected"], (val) => !val)
        .update("orders", (arr) => {
            const iArray = List(arr);
            const isParentSelected = state.getIn(["orders", action.index, "parent_selected"], (val) => val);

            let isParentSelectedUpdate = false;
            if (_.isFunction(isParentSelected)) {
                isParentSelectedUpdate = true;
            } else {
                isParentSelectedUpdate = !isParentSelected;
            }

            const newArray = iArray.toJS();
            if (isParentSelectedUpdate) {
                newArray[action.index].items.map((item) => {
                    if (item.platform_package_id) {
                        item["selected"] = true;
                    }
                });
            } else {
                newArray[action.index].items.map((item) => {
                    item["selected"] = false;
                });
            }
            return List(newArray);
        })
        .update("selectedOrders", (arr) => {
            const iArray = List(arr);
            const isParentSelected = state.getIn(["orders", action.index, "parent_selected"], (val) => val);

            let isParentSelectedUpdate = false;
            if (_.isFunction(isParentSelected)) {
                isParentSelectedUpdate = true;
            } else {
                isParentSelectedUpdate = !isParentSelected;
            }

            const newArray = iArray.toJS();
            if (isParentSelectedUpdate) {
                action.data.map((i) => {
                    const idIdx = _.findIndex(newArray, i);
                    idIdx === -1 && !_.includes(newArray, i) && newArray.push(i);
                });
            } else if (!isParentSelectedUpdate && newArray.length > 0) {
                action.data.map((i) => {
                    newArray.splice(_.findIndex(newArray, i), 1);
                });
            }
            return List(newArray);
        });

export const updateSelectedOrder = (state, action) =>
    state.update("selectedOrdersDisplay", (arr) => {
        const iArray = List(arr);
        const orderID = action.data.platform_order_id;
        const newArray = iArray.toJS();
        const idIdx = _.findIndex(newArray, orderID);
        action.checked
            ? idIdx === -1 && !_.includes(newArray, orderID) && newArray.push(orderID)
            : _.remove(newArray, (item) => item === orderID);

        return List(newArray);
    });

export const handleSelectMaster = (state) =>
    state
        .update("selectAllMaster", (val) => !val)
        .update("orders", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");

            const newArray = iArray.toJS();
            if (selectAllMaster) {
                newArray.map((i) => {
                    const iter = [];

                    i.items.map((j) => {
                        if (j.platform_package_id) {
                            j["selected"] = true;
                            iter.push(true);
                        } else {
                            j["selected"] = false;
                        }
                    });

                    if (iter.length === i.items.length) i["parent_selected"] = true;
                });
            } else {
                newArray.map((i) => {
                    i["parent_selected"] = false;

                    i.items.map((j) => {
                        j["selected"] = false;
                    });
                });
            }

            return List(newArray);
        })
        .update("selectedOrders", (arr) => {
            const iArray = List(arr);
            const selectAllMaster = !state.get("selectAllMaster");
            const orders = state.get("orders").toJS();

            const newArray = iArray.toJS();
            if (selectAllMaster) {
                orders.map((i) => {
                    i.items.map((j) => {
                        if (j.platform_package_id) {
                            if (
                                _.findIndex(newArray, {
                                    id: j.package_id,
                                    shop_sku: j.shop_sku,
                                }) === -1
                            ) {
                                newArray.push({
                                    id: j.package_id,
                                    shop_sku: j.shop_sku,
                                });
                            }
                        }
                    });
                });
            } else {
                orders.map((i) => {
                    i.items.map((j) => {
                        if (j.platform_package_id) {
                            newArray.splice(
                                _.findIndex(newArray, {
                                    id: j.package_id,
                                    shop_sku: j.shop_sku,
                                }),
                                1,
                            );
                        }
                    });
                });
            }

            return List(newArray);
        });

export const updateMasterSelectOrders = (state, action) =>
    state.update("selectedOrdersDisplay", (arr) => {
        const iArray = List(arr);
        const orders = state.get("orders").toJS();
        const isChecked = action.checked;

        const newArray = iArray.toJS();

        orders.map((i) => {
            const idIdx = _.findIndex(newArray, i.platform_order_id);
            isChecked
                ? idIdx === -1 &&
                  !_.includes(newArray, i.platform_order_id) &&
                  i.parent_selected === true &&
                  newArray.push(i.platform_order_id)
                : _.remove(newArray, (item) => item === i.platform_order_id);
        });

        return List(newArray);
    });

export const handleDeselectAll = (state) =>
    state
        .set("selectAllMaster", false)
        .update("selectedOrders", () => List([]))
        .update("selectedOrdersDisplay", () => List([]))
        .update("orders", (arr) => {
            const iArray = List(arr);

            const newArray = iArray.toJS();
            newArray.map((i) => {
                i["parent_selected"] = false;
                i.items.map((j) => {
                    j["selected"] = false;
                });
            });
            return List(newArray);
        });

export default function ordersReducer(state = initState, action) {
    switch (action.type) {
        case FETCH_ORDERS_SUCCESS:
            return setOrders(state, action);

        case FETCH_ORDERS_FAILED:
            return setError(state, action.err);

        case FETCH_FILTER_OPTIONS_SUCCESS:
            return setSearchOptions(state, action);

        case FETCH_FILTER_OPTIONS_FAILED:
            return setError(state, action.err);

        case CLEAR_ORDERS:
            return clearOrdersState(state);

        case CLEAR_ALL_ORDERS:
            return clearAllOrdersState(state);

        case PRINT_SELECTED_SUCCESS:
            return setPDFResults(state, action);

        case PRINT_SELECTED_FAILED:
            return setError(state, action.err);

        case PRINT_ONE_ROW_SUCCESS:
            return setPDFResults(state, action);

        case PRINT_ONE_ROW_FAILED:
            return setError(state, action.err);

        case TOGGLE_TABLE_LOADING:
            return toggleTableLoadingState(state);

        case VALUED_TABLE_LOADING:
            return valuedTableLoadingState(state, action);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case TOGGLE_FIRST_LOAD:
            return toggleFirstLoad(state);

        case HANDLE_SELECT_ONE:
            return handleSelectOne(state, action);

        case HANDLE_SELECT_PARENT:
            return handleSelectParent(state, action);

        case HANDLE_SELECT_MASTER:
            return handleSelectMaster(state);

        case UPDATE_SELECT_MASTER:
            return updateSelectMaster(state);

        case HANDLE_DESELECT_ALL:
            return handleDeselectAll(state);

        case UPDATE_DROPDOWNS:
            return updateDropdowns(state, action);

        case UPDATE_INPUTS:
            return updateInputs(state, action);

        case UPDATE_DATE_RANGE:
            return updateDateRange(state, action);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case SET_ZIP_RESULT:
            return setZipResult(state, action);

        case CLEAR_AFTER_PRINT:
            return clearAfterPrintState(state);

        case SORT_CHANGE:
            return updateSortChange(state, action);

        case UPDATE_SELECTED_ORDER:
            return updateSelectedOrder(state, action);

        case UPDATE_MASTER_SELECTED_ORDER:
            return updateMasterSelectOrders(state, action);

        default:
            return state;
    }
}
