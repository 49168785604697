export const setCookie = (name, value, numberOFExpiryDays) => {
    const date = new Date();
    date.setTime(date.getTime() + numberOFExpiryDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/;secure`;
};

export const getCookie = (name) => {
    const nameWithEqualSign = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    const value = cookieArray.reduce((acc, cur) => {
        // to trim empty string in the first string index.
        if (cur.charAt(0) === " ") {
            // eslint-disable-next-line no-param-reassign
            cur = cur.substr(1);
        }

        // get the exact cookie value.
        if (cur.indexOf(nameWithEqualSign) === 0) {
            return cur.substr(nameWithEqualSign.length, cur.length);
        }
        return acc;
    }, "");

    return value || "";
};

export const delCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
