import { createSelector } from "reselect";

export const collectionReceiptSelector = (state) => state.get("collectionReceiptReducer");

export const makeSelectCollectionReceipt = createSelector(
    collectionReceiptSelector,
    (collectionReceiptState) => collectionReceiptState.get("collectionReceipt") || [],
);

export const makeSelectFile = createSelector(
    collectionReceiptSelector,
    (collectionReceiptState) => collectionReceiptState.get("file") || {},
);

export const makeSelectFormOpenState = createSelector(collectionReceiptSelector, (collectionReceiptState) =>
    collectionReceiptState.get("isFormOpen"),
);

export const makeSelectModalState = createSelector(collectionReceiptSelector, (collectionReceiptState) =>
    collectionReceiptState.get("isModalOpen"),
);

export const makeSelectIsModalLoading = createSelector(collectionReceiptSelector, (collectionReceiptState) =>
    collectionReceiptState.get("isModalLoading"),
);

export const makeSelectPDFResults = createSelector(collectionReceiptSelector, (collectionReceiptState) =>
    collectionReceiptState.get("pdfResults"),
);

export const makeSelectBusinessDetails = createSelector(collectionReceiptSelector, (collectionReceiptState) =>
    collectionReceiptState.get("businessDetails").toJS(),
);
