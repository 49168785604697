import { takeLatest, put, call, select, all } from "redux-saga/effects";
import _ from "lodash";
import apiCall from "../../utils/ApiService";
import AlertMessage from "../../components/AlertMessage";
import { PRINT_COLLECTION_RECEIPT } from "./constants";

import { toggleModalState, toggleIsModalLoading, printOneRowSuccess, printOneRowFailed } from "./actions";

import { makeSelectCollectionReceipt, makeSelectBusinessDetails } from "./selectors";

export function* saveCollectionReceiptWorker() {
    try {
        const collectionReceipt = yield select(makeSelectCollectionReceipt);
        const businessDetails = yield select(makeSelectBusinessDetails);

        const labeledCR = collectionReceipt.map((data) => ({
            ...data,
            total_sales: 0,
            amount_paid: 0,
            sum_amount: "",
        }));

        const labeledInvoices = collectionReceipt.map((data) => ({
            invoice_number: data.invoice_number,
            invoice_amount: data.invoice_amount,
        }));

        const sumAmt = "sum_amount";
        const newLabeledCR = labeledCR.map((data) =>
            Object.entries(data).reduce(
                // eslint-disable-next-line no-return-assign, no-param-reassign
                (a, [k, v]) => (k !== sumAmt && v === "" ? a : ((a[k] = v), a)),
                {},
            ),
        );

        const invoices = labeledInvoices.map((data) =>
            Object.entries(data).reduce(
                // eslint-disable-next-line no-return-assign, no-param-reassign
                (a, [k, v]) => (v === "" ? a : ((a[k] = v), a)),
                {},
            ),
        );

        const crData = _.omit(newLabeledCR[0], ["invoice_number", "invoice_amount", "key"]);

        const response = yield call(apiCall, "POST", `/collection-receipts/print`, {
            responseType: "arraybuffer",
            data: {
                collection_receipt_data: [{ ...{ invoices }, ...crData }],
                collection_receipt_header: businessDetails,
            },
        });

        const newBlob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(newBlob);
        return yield all([
            put(toggleModalState()),
            put(toggleIsModalLoading()),
            put(printOneRowSuccess(url)),
            put(toggleIsModalLoading()),
        ]);
    } catch ({ response }) {
        AlertMessage("error", "Print Failed");
        yield printOneRowFailed(response.error);
        yield put(toggleModalState());
    }

    return 0;
}

export default function* collectionReceiptWatcher() {
    yield takeLatest(PRINT_COLLECTION_RECEIPT, saveCollectionReceiptWorker);
}
