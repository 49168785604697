import {
    VERIFY,
    VERIFY_FAILED,
    VERIFY_SUCCESS,
    EDITSHOP,
    EDITSHOP_SUCCESS,
    EDITSHOP_FAILED,
    CLEAR_VERIFY,
} from "./constants";

// VERIFY
export const verify = (shopifyData) => ({
    type: VERIFY,
    shopifyData,
});

export const verifySuccess = (currentShop) => ({
    type: VERIFY_SUCCESS,
    currentShop,
});

export const verifyFailed = (err) => ({
    type: VERIFY_FAILED,
    err,
});

// SUBMIT
export const editShop = (submitData) => ({
    type: EDITSHOP,
    submitData,
});

export const editShopSuccess = () => ({
    type: EDITSHOP_SUCCESS,
});

export const editShopFailed = (err) => ({
    type: EDITSHOP_FAILED,
    err,
});

export const clearVerify = () => ({
    type: CLEAR_VERIFY,
});
