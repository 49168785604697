import { takeLatest } from "redux-saga/effects";
import { REGISTER } from "./constants";

export function* registerWorker() {
    try {
        yield console.log("regs sssss");
    } catch (err) {
        yield console.log("regs err");
    }
}

export default function* registerWatcher() {
    yield takeLatest(REGISTER, registerWorker);
}
