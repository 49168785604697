import { fromJS } from "immutable";
import {
    HANDLE_PRINT_FAILED,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    CLEAR_REPORTS,
    UPDATE_DATE_RANGE,
    FILENAME_POPUP,
    SET_ZIP_RESULT,
} from "./constants";

export const initState = fromJS({
    err: null,
    tableControls: {
        start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
        end_date: new Date(),
    },
    isModalOpen: false,
    isModalLoading: false,
});

// set ERROR
export const setError = (state, err) => state.set("err", err);

// set platforms
export const setPlatforms = (state, action) => state.update("tableControls", () => action.tableControls);

// clear DATAS
export const clearReportsState = (state) => state.update("platforms", () => initState.get("platforms"));

// download loading
export const toggleModalState = (state) => state.update("isModalOpen", (isOpen) => !isOpen);

// print loading
export const toggleModalLoadingState = (state) => state.update("isModalLoading", (loading) => !loading);

// update date range
export const updateDateRange = (state, action) => state.update("tableControls", (data) => data.merge(action.data));

// zip file download
export const setZipResult = (state, action) => state.set("zipResults", action.data);

export const fileNameModalState = (state) => state.update("isFNModalOpen", (isOpen) => !isOpen);

export default function reportsReducer(state = initState, action) {
    switch (action.type) {
        case HANDLE_PRINT_FAILED:
            return setError(state, action.err);

        case TOGGLE_MODAL:
            return toggleModalState(state);

        case TOGGLE_MODAL_LOADING:
            return toggleModalLoadingState(state);

        case CLEAR_REPORTS:
            return clearReportsState(state, action);

        case UPDATE_DATE_RANGE:
            return updateDateRange(state, action);

        case FILENAME_POPUP:
            return fileNameModalState(state);

        case SET_ZIP_RESULT:
            return setZipResult(state, action);

        default:
            return state;
    }
}
