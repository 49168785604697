import { takeLatest, put, call, select } from "redux-saga/effects";
import apiCall from "../../utils/ApiService";
import { fetchAccountSuccess, getFileSuccess, getFileFailed, fetchAccount, toggleUploading } from "./actions";
import { FETCH_ACCOUNT, EDIT_ACCOUNT, GET_FILE } from "./constants";
import AlertMessage from "../../components/AlertMessage";
import { makeSelectUploading } from "./selectors";
import AlertLoading from "../../components/AlertLoading";

const accountURL = "/accounts";
const userURL = "/users/me";

export function* fetchAccountWorker() {
    try {
        const { accounts } = yield call(apiCall, "GET", userURL);

        yield put(fetchAccountSuccess(accounts));
    } catch ({ response }) {
        AlertMessage("error", response.data.message);
    }
}

export function* editAccountWorker({ id, editedAccount }) {
    try {
        yield put(toggleUploading());
        AlertLoading(yield select(makeSelectUploading), "Uploading...");

        yield call(apiCall, "POST", `${accountURL}/${id}/uploadFiles`, {
            data: editedAccount,
            headers: {
                "Content-Type": "application/json",
            },
        });

        yield put(fetchAccount());
        yield put(toggleUploading());
        AlertLoading(yield select(makeSelectUploading), "Success...");
    } catch (error) {
        AlertMessage("error", "Fetching failed!");
    }
}

export function* getFileWorker({ file }) {
    try {
        const data = yield call(apiCall, "GET", `/file/${file}?get_actual_file=true`, {
            responseType: "blob",
        });

        const newBlob = new Blob([data], { type: "application/pdf" });

        const url = URL.createObjectURL(newBlob);

        yield put(getFileSuccess(url));
    } catch (error) {
        yield put(getFileFailed(error));
    }
}

export default function* accountWatcher() {
    yield takeLatest(FETCH_ACCOUNT, fetchAccountWorker);
    yield takeLatest(EDIT_ACCOUNT, editAccountWorker);
    yield takeLatest(GET_FILE, getFileWorker);
}
