import {
    IMPORT_CR,
    PRINT_COLLECTION_RECEIPT,
    SAVE_FILE,
    CLEAR_COLLECTION_RECEIPT,
    TOGGLE_FORM_OPEN,
    TOGGLE_MODAL,
    TOGGLE_MODAL_LOADING,
    PRINT_ONE_ROW,
    PRINT_ONE_ROW_SUCCESS,
    PRINT_ONE_ROW_FAILED,
    UPDATE_INPUTS,
    CLEAR_AFTER_PRINT,
} from "./constants";

export const importCR = (data) => ({
    type: IMPORT_CR,
    data,
});

export const printCollectionReceipt = () => ({
    type: PRINT_COLLECTION_RECEIPT,
});

export const saveFile = (file) => ({
    type: SAVE_FILE,
    file,
});

export const clearCollectionReceipt = () => ({
    type: CLEAR_COLLECTION_RECEIPT,
});

export const toggleFormOpen = () => ({
    type: TOGGLE_FORM_OPEN,
});

export const toggleModalState = () => ({
    type: TOGGLE_MODAL,
});

export const toggleIsModalLoading = () => ({
    type: TOGGLE_MODAL_LOADING,
});

export const printOneRow = () => ({
    type: PRINT_ONE_ROW,
});

export const printOneRowSuccess = (data) => ({
    type: PRINT_ONE_ROW_SUCCESS,
    data,
});

export const printOneRowFailed = (err) => ({
    type: PRINT_ONE_ROW_FAILED,
    err,
});

export const updateInputs = (name, value) => ({
    type: UPDATE_INPUTS,
    name,
    value,
});

export const clearAfterPrint = () => ({
    type: CLEAR_AFTER_PRINT,
});
